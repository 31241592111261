import React from "react"
import useToggleStates from "@src/hooks/useToggleStates"
import { useCartStore } from "@src/hooks/useStore"

const useQuantity = () => {
  const { cart } = useCartStore(["cart"])
  const items = cart ? cart.lines : []
  const total = items?.reduce((acc, item) => acc + item.quantity, 0)

  return total
}

const CartToggle = ({ className }) => {
  const { isDrawerOpen } = useCartStore(["isDrawerOpen"])

  const { toggleAllFunction } = useToggleStates()
  const quantity = useQuantity()
  const hasQuantity = quantity > 0

  const handleCartClick = () => {
    toggleAllFunction("drawer", !isDrawerOpen)
  }

  return (
    <button
      id="cart-drawer-toggle"
      onClick={handleCartClick}
      className={`${className} flex items-center`}
    >
      <span>Cart</span>
      {hasQuantity && (
        <span className="relative ml-1 pt-[4px] border-1 border-solid border-black rounded-full text-sm leading-none w-5 h-5 flex justify-center items-center">
          {quantity}
          <span className="sr-only">items in cart</span>
        </span>
      )}
    </button>
  )
}

export default CartToggle
