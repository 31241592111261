import { addDiscount, addLineItems, initializeCart, removeDiscount, removeLineItems, toggleDrawer, updateLineItems } from "@src/actions/cart"

const initialState = {
    loading: false,
    cart: { lines: [] },
    isDrawerOpen: false,
    upsells: [],
    under50: [],
}

const cartSlice = (set, get) => ({

    ...initialState,

    initializeCart: () => initializeCart(set, get),

    addLineItems: (lines) => addLineItems(set, get, lines),

    removeLineItems: (cartId, lineIds) => removeLineItems(set, get, cartId, lineIds),

    updateLineItems: (cartId, lines) => updateLineItems(set, cartId, lines),

    addDiscount: (discountCodes) => addDiscount(set, get, discountCodes),

    removeDiscount: () => removeDiscount(set, get),

    toggleDrawer: (override) => toggleDrawer(set, override),
})

export default cartSlice;