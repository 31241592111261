import React from 'react'
import Button from '@components/button'
import { useSearchStore } from '@src/hooks/useStore';
import useToggleStates from '@src/hooks/useToggleStates';


const SearchToggle = ({ className }) => {
  const { isOpen } = useSearchStore(["isOpen"]);
  const { toggleAllFunction } = useToggleStates();

  const handleSearchToggle = () => {
    toggleAllFunction("search", !isOpen)
  };

  return (
    <Button
      id="search-toggle-button"
      ctaWidth={"none"}
      className={`${className || ""} search-toggle-button cursor-pointer font-normal md:py-2.5 md:px-3 md:border md:border-[#9DA3AE] md:rounded-xl md:max-w-[150px] text-lg ${!isOpen ? 'flex' : ''}`}
      theme="btn-none"
      themeHover="btn-none"
      onClick={handleSearchToggle}
      ariaLabel={"Search Toggle Button"}
    >
      <span className='max-md:flex md:!hidden'>Search</span>
      <svg className='!flex' width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.92385 1.23923e-08C5.07312 7.46641e-05 4.23473 0.209698 3.47865 0.611381C2.72257 1.01306 2.07071 1.59516 1.57746 2.3091C1.08421 3.02304 0.763872 3.84813 0.643172 4.71552C0.522472 5.58291 0.604911 6.46745 0.88361 7.29536C1.16231 8.12326 1.62919 8.87051 2.24529 9.47476C2.86139 10.079 3.60886 10.5228 4.42532 10.769C5.24179 11.0152 6.10358 11.0567 6.93879 10.89C7.774 10.7234 8.55842 10.3535 9.22659 9.81111L11.5188 12.1721C11.6372 12.2899 11.7957 12.355 11.9603 12.3535C12.1249 12.3521 12.2823 12.2841 12.3987 12.1642C12.515 12.0444 12.581 11.8822 12.5825 11.7127C12.5839 11.5432 12.5206 11.3799 12.4063 11.258L10.1141 8.89698C10.7342 8.08668 11.1203 7.11293 11.2283 6.08716C11.3362 5.06139 11.1615 4.02505 10.7243 3.09674C10.2871 2.16843 9.605 1.38566 8.75604 0.837999C7.90708 0.290342 6.92557 -6.9267e-05 5.92385 1.23923e-08ZM1.84407 5.49515C1.84407 4.38067 2.2739 3.31183 3.03901 2.52377C3.80412 1.73571 4.84182 1.29298 5.92385 1.29298C7.00588 1.29298 8.04358 1.73571 8.80869 2.52377C9.5738 3.31183 10.0036 4.38067 10.0036 5.49515C10.0036 6.60964 9.5738 7.67848 8.80869 8.46654C8.04358 9.2546 7.00588 9.69733 5.92385 9.69733C4.84182 9.69733 3.80412 9.2546 3.03901 8.46654C2.2739 7.67848 1.84407 6.60964 1.84407 5.49515Z" fill="#191818"/>
      </svg>
    </Button>
  )
}

export default SearchToggle