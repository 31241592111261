import React, { useEffect, useState } from "react"
import AlertDialog from "../alert";
import { useSiteSettingsStore } from "@src/hooks/useStore";

const QuantityInput = ({ currentQuantity, onUpdate, maxQuantity, minQuantity, className }) => {
  const [updating, setUpdating] = useState(false)
  const [inputWidth, setInputWidth] = useState(1);

  const { setAlert } = useSiteSettingsStore(["setAlert"]);

  const updateQuantity = (newQuantity, type) => {
    if (type == "increase") {
      setAlert(<AlertDialog>Quantity increased to {newQuantity}</AlertDialog>)
    } else {
      setAlert(<AlertDialog>Quantity decreased to {newQuantity}</AlertDialog>)
    }

    setUpdating(true)
    onUpdate(newQuantity)
  }

  const setFromInput = e => {
    let value = e.target.value

    setInputWidth(value.length)

    if (value === "") {
      value = 1
    }

    updateQuantity(parseInt(value))
  }

  useEffect(() => {
    setUpdating(false)
    setInputWidth(currentQuantity.toString().length)
  }, [currentQuantity])

  const isMax = maxQuantity && currentQuantity === maxQuantity;
  const isMin = minQuantity && currentQuantity === minQuantity;

  return (
    <div className={`ml-auto flex py-2 border-1 rounded-xl gap-x-1.5 ${updating ? 'border-darker-gray' : 'border-soft-black'} ${className ? className : ''}`} aria-label="Quantity Selector">
      <button
        type="button"
        className={`items-center text-base font-medium text-center px-3.5 ${updating ? 'text-darker-gray' : 'text-soft-black'}`}
        onClick={() => updateQuantity(currentQuantity - 1, "decrease")}
        disabled={updating || isMin}
        aria-label="Decrease Quantity Button"
      >
        <span className="">-</span>
      </button>
      <input
        type="text"
        value={currentQuantity}
        className={`text-base text-center font-medium ${updating ? 'text-darker-gray' : 'text-soft-black'}`}
        onChange={setFromInput}
        aria-label="Quantity Input"
        size={inputWidth}
      />
      <button
        type="button"
        className={`items-center text-base font-medium text-center px-3.5 ${updating ? 'text-darker-gray' : 'text-soft-black'}`}
        onClick={() => updateQuantity(currentQuantity + 1, "increase")}
        disabled={updating || isMax}
        aria-label="Increase Quantity Button"
      >
        <span className="">+</span>
      </button>
    </div>
  )
}

export default QuantityInput;