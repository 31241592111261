import React, { useEffect, useRef, useState } from 'react'
import { useForm } from "react-hook-form"
import { subscribe, fieldWidth, addClassToElements } from './helpers'
import Field from '../form/field'
import Section from '../section'
import Column from '../column'
import ColumnItem from '../rebrand/column-item'
import Button from '../button'
import FieldGroup from '../field-group'
import { ReactComponent as Tick } from '@svgs/tick.svg'
import { trackSubscribe } from '@src/services/analytics'

const Newsletter = ({ heading, subheading, inputs, defaultSettings }) => {
  const formRef = useRef();
  const { register, handleSubmit, errors } = useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const onSubmit = async ({ email, name, birthday, breed }) => {
    setIsSubmitting(true)

    const { errors, success } = await subscribe("PgpgwK", email, {
      "Pups Birthday": birthday,
      "Pups Name": name,
      "Pups Breed": breed,
    })

    setTimeout(() => {
      trackSubscribe(email)
      window.TriplePixel('Contact', {email});
    }, 100)

    setIsSubmitting(false)
    setIsSubmitted(true)
  }

  useEffect(() => {
    const oneThirdElements = formRef?.current?.querySelectorAll('.input-one-third')
    const halfElements = formRef?.current?.querySelectorAll('.input-half')

    addClassToElements([...oneThirdElements], 'width-one-third', 3);
    addClassToElements([...halfElements], 'width-half', 2);
  }, [formRef?.current])

  return (
    <Section settings={defaultSettings} innerClassname="gap-0 md:gap-0 newsletter-form max-md:flex-col">
      <Column className="max-md:gap-y-2.5 max-md:pb-0">
        <ColumnItem>
          <h2 className="headline text-[32px]">{heading}</h2>
        </ColumnItem>
        <ColumnItem className="gap-y-2.5 md:gap-y-2.5">
          <p>{subheading}</p>
          <p className="text-xs max-md:hidden">*Indicates required fields</p>
        </ColumnItem>
      </Column>
      <Column className={`max-md:gap-y-2.5 ${isSubmitted ? 'hidden' : ''}`}>
        <form
          className="flex flex-col gap-y-2.5 md:gap-y-5"
          onSubmit={handleSubmit(onSubmit)}
          encType="multipart/form-data"
          ref={formRef}
        >
          <div className="flex flex-wrap bg-darker-gray rounded-xl overflow-hidden justify-between gap-[1px]">
            {inputs?.map(({ label, name, required, selectionElements, type, placeholder, width }, index) => {
              if ((type == 'checkbox' || type == 'radio') && selectionElements?.length > 0) {
                return (
                  <FieldGroup key={index} label={label} elements={selectionElements} name={name} type={type} required={required} register={register} errors={errors} className={`${fieldWidth[width] || ''}`} />
                )
              }
              return (
                <div key={index} className={`${fieldWidth[width] || ''} p-5`} aria-label={label + ' Input Field'} role={''} >
                  <Field
                    name={name}
                    type={type}
                    label={label}
                    placeholder={placeholder}
                    register={register}
                    required={required}
                    errors={errors}
                  />
                </div>
              )
            })}
          </div>
          <Button buttonType="submit" theme="btn-secondary" themeHover="btn-primary" ctaWidth={"full"}>{isSubmitting ? 'Submitting...' : 'Submit'}</Button>
        </form>
        <p className="text-xs md:hidden">*Indicates required fields</p>
      </Column>
      <Column className={`text-center ${isSubmitted ? '' : 'hidden'} !justify-center`}>
        <Tick className="w-20 h-20 mx-auto bg-safety-green fill-white rounded-full p-2" />
      </Column>
    </Section>
  )
}

export default Newsletter