import React, { useEffect, useState } from "react"
import getValue from "get-value"
import { placeholderImageId } from "@utils/image"
import { uid } from "react-uid"
import Slider from "react-slick"
import VideoBlock from "../../video"
import { ReactComponent as SliderArrowNext } from "@svgs/slider-arrow-next.svg"
import { ReactComponent as SliderArrowPrev } from "@svgs/slider-arrow-prev.svg"
import { onEnterKeyPress } from "@src/utils/general"
import Image from "@src/components/image"
import { videoSlideHandler } from "./helpers"
import { useQuickViewStore, useProductStore } from "@src/hooks/useStore"

const ImageCarousel = ({
  images,
  initialImages,
  productEntry,
  isQuickView,
  customMainSliderSettings,
  thumbnails = true,
  customThumbnailSliderSettings,
  lazyLoad = false,
  bundleImageDescription = true,
  mainImageClassName = '',
  thumbnailImageClassName = '',
}) => {

  const { currentVariant } = useProductStore(["currentVariant"]);
  const { quickViewVariant } = useQuickViewStore(["quickViewVariant"]);
  const [currentImages, setCurrentImages] = useState(null);

  useEffect(() => {
    let imagesToSet;
  
    if (images && images.length > 0) {
      imagesToSet = images;
    } else if (!isQuickView) {
      const variantImages = currentVariant?.variantImages;
      const listingImage = currentVariant?.listingImage ? [currentVariant.listingImage] : null;
      imagesToSet = variantImages || listingImage || initialImages;
    } else {
      imagesToSet = quickViewVariant?.variantImages;
    }
  
    setCurrentImages(imagesToSet);
  }, [images, currentVariant, quickViewVariant]);
  

  // console.log(initialImages, 'initialImages')
  
  const [mainNav, setMainNav] = useState()
  const [thumbnailNav, setThumbnailNav] = useState()

  const slideTo = index => mainNav?.slickGoTo(index)

  let mainImageSettings = {
    dots: false,
    accessibility: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    adaptiveHeight: false,
    className: `pdp--main-slider ${mainImageClassName}`,
    lazyLoad: lazyLoad,
    nextArrow: <SliderArrowComponent />,
    prevArrow: <SliderArrowComponent rotate={true} />,
    ...customMainSliderSettings,
		beforeChange: videoSlideHandler,
    onReInit: videoSlideHandler,
  }

  let thumbnailImageSettings = {
    dots: false,
    accessibility: true,
    arrows: false,
    draggable: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    lazyLoad: lazyLoad,
    className: `pdp--thumbnail-slider ${thumbnailImageClassName}`,
    ...customThumbnailSliderSettings,
  }

  const classList = `image-gallery-wrapper aspect-1 md:sticky bg-light-gray rounded-xl overflow-hidden w-full ${
    !isQuickView ? "top-10" : "md:top-28"
  }`

  if (!currentImages || currentImages.length === 0) {
    return <></>;
  }

  return (
    <div className={classList}>
      {currentImages && (
        <>
          <Slider
            ref={slider1 => setMainNav(slider1)}
            asNavFor={thumbnailNav}
            {...mainImageSettings}
            tabIndex={0}
          >
            {currentImages?.map(image => {
              const { _type: type } = image

              const img = type === "bundleImage" ? image?.image : image

              const imageAssetId =
                getValue(img, "asset._id") || placeholderImageId

              const imageObject = img?.asset
              const imageAlt = imageObject?.altText
                ? imageObject?.altText
                : imageObject?.filename
                    ?.replace(/\.(.*)$/, "")
                    ?.replace(/[^a-zA-Z0-9]+/g, " ")

              if (type == "video") {
                return (
                  <div key={uid({ imageAssetId })} className="h-fit video-slide">
                    <div className="flex items-center justify-center h-fit">
                      <VideoBlock
                        url={image?.url}
                        placeholderImage={image?.placeholderImage}
                        vimeoVideoId={image?.vimeoVideoId}
                        video={image?.video}
                        muted={image?.muted}
                        loop={image?.loop}
                        aspectRatio={image?.aspectRatio}
                        autoplay={image?.autoplay}
                        controls={image?.controls}
                        maxWidth={800}
                      />
                    </div>
                  </div>
                )
              }
              return (
                <div
                  className="relative overflow-hidden h-full"
                  key={uid({ imageAssetId })}
                  tabIndex={0}
                >
                  <Image
                    className="w-full h-full rounded-xl md:rounded-3xl"
                    alt={
                      type == "shopifyImage"
                        ? img?.description || `${productEntry?.title} product image`
                        : imageAlt
                    }
                    image={img}
                    width={800}
                    height={800}
                  />

                  {image.description && bundleImageDescription && (
                    <p className="hidden sm:flex absolute top-3 left-3 mr-3 rounded-xl px-3 py-1 text-xs leading-4 text-[#939393] bg-[#f7f7f7] shadow-[0px_0px_2px_rgba(0,0,0,0.25)]">
                      Pictured: &nbsp;
                      <span className="text-black">{image.description}</span>
                    </p>
                  )}
                </div>
              )
            })}
          </Slider>

          {/* Thumbnails slider */}
          {thumbnails && currentImages.length > 1 && (
            <Slider
              ref={slider2 => setThumbnailNav(slider2)}
              asNavFor={mainNav}
              {...thumbnailImageSettings}
            >
              {currentImages?.map((image, index) => {
                const img = image?.image ? image?.image : image

                const { _type: type } = img
                const imageAssetId =
                  getValue(img, "asset._id") ||
                  getValue(img?.placeholderImage, "asset._id") ||
                  placeholderImageId

                const imageObject = img?.asset
                const imageAlt = imageObject?.altText
                  ? imageObject?.altText
                  : imageObject?.filename
                      ?.replace(/\.(.*)$/, "")
                      ?.replace(/[^a-zA-Z0-9]+/g, " ")

                return (
                  <div
                    className="relative overflow-hidden aspect-[13/9]"
                    key={uid({ imageAssetId })}
                    onClick={() => slideTo(index)}
                    tabIndex={0}
                  >
                    {type === "video" && image.placeholderImage ? (
                      <Image
                        className="rounded-xl w-full h-full object-cover"
                        alt={
                          type == "shopifyImage"
                            ? img?.description || `${productEntry?.title} product image`
                            : imageAlt
                        }
                        image={image.placeholderImage}
                        width={135}
                        height={95}
                      />
                    ) : (
                      <Image
                        className="rounded-xl w-full h-full object-cover"
                        alt={
                          type == "shopifyImage"
                            ? img?.description || `${productEntry?.title} product image`
                            : imageAlt
                        }
                        image={img}
                        width={135}
                        height={95}
                      />
                    )}
                  </div>
                )
              })}
            </Slider>
          )}
        </>
      )}
    </div>
  )
}

const SliderArrowComponent = ({ rotate = false, onClick }) => {
  const classList = `absolute z-10 top-1/2 -translate-y-1/2 cursor-pointer ${
    rotate ? "left-0" : "-right-[1px]"
  }`
  return (
    <div
      onClick={onClick}
      onKeyDown={e => onEnterKeyPress(e, onClick)}
      className={classList}
      tabIndex={0}
      role="button"
      aria-label={
        rotate
          ? "Product Gallery Previous Button"
          : "Product Gallery Next Button"
      }
    >
      {rotate ? <SliderArrowPrev /> : <SliderArrowNext />}
    </div>
  )
}

export default ImageCarousel