import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import React from "react"
import { useCartStore } from "@src/hooks/useStore"

export const TokiScript = () => {
  const { addDiscount, cart } = useCartStore(["addDiscount", "cart"]);
  const [referralDiscountCode, setReferralDiscountCode] = useState()
  const [script, setScript] = useState()

  useEffect(() => {
    window.Shopify = { shop: process.env.SHP_NAME + ".myshopify.com" }

    async function main() {
      const config = await fetch(
        `https://api.buildwithtoki.com/integrations/shopify/config`,
        {
          headers: {
            "x-shopify-shop-domain": window.Shopify.shop,
            "X-Shopify-Storefront-Access-Token": process.env.SHP_TOKEN,
          },
        }
      ).then(res => res.json())

      setScript({
        url: `${config.snippets.url}/modules.js?v=${config.snippets.version}`,
        config: JSON.stringify(config),
      })
    }

    main().catch(e => console.error("Toki init failed: ", e))
  }, [])

  useEffect(() => {
    async function applyDiscountCode(event) {
      setReferralDiscountCode(event.detail.discountCode)
    }

    const eventName = "toki:referral-discount-code:fetched"

    document.addEventListener(eventName, applyDiscountCode)

    return () => document.removeEventListener(eventName, applyDiscountCode)
  }, [])

  useEffect(() => {
    if (!referralDiscountCode || !cart?.id) {
      return
    }

    addDiscount(referralDiscountCode)
  }, [referralDiscountCode, cart?.id])

  if (!script) {
    return null
  }

  return (
    <Helmet>
      <script
        id="toki-modules"
        type="text/javascript"
        src={script.url}
        data-config={script.config}
      />
    </Helmet>
  )
}
