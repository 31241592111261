import React from "react"
import * as styles from "./richText.module.css"
import PortableText from "react-portable-text"
import { useUID } from "react-uid"
import Heading from "@components/heading"
import CustomList from "@components/list"
import { checkLink } from "@utils/portable-text"
import { Link } from "gatsby"
import Image from "../image"

const RichText = ({
  text = "",
  childrenHTML,
  constrainWidth = false,
  tag: Tag = "p",
  className,
  renderContainerOnSingleChild = true,
  paragraphClassName,
  removeOtherClasses = false,
  children,
  listClassname,
}) => {
  let classList = `${styles.copyWrapper} ${constrainWidth ? styles.copyWrapperConstrained : ""} ${className || ""}`;
  let paragraphClassList = `mb-2.5 md:mb-5 ${paragraphClassName}`

  const createMarkup = () => ({ __html: childrenHTML })

  if (removeOtherClasses) {
    classList = className || ""
  }

  const serializers = {
    code: ({ node: { language, code } }) => (
      <pre data-language={language}>
        <code>{code}</code>
      </pre>
    ),
    link: ({ blank, nofollow, href, children }) => {
      const linkType = checkLink(href)?.type
      const isKlaviyo = linkType === "klaviyo"

      const handleClick = e => {
        e.preventDefault();
        klaviyo.openForm(checkLink(href)?.value);
      }

      return (
        <Link
          href={isKlaviyo ? "#" : checkLink(href)?.value}
          target={blank ? "_blank" : "_self"}
          rel={nofollow ? "nofollow" : ""}
          onClick={isKlaviyo ? handleClick : null}
          className={`${isKlaviyo ? "klaviyo-retrigger" : ""} hover:text-safety-green`}
          >
          {children}
        </Link>
      )
    },
    ul: ({ children }) => (
      <CustomList tag="ul" blockKey={`copyBlock-${useUID()}`} className={listClassname}>
        {children}
      </CustomList>
    ),
    li: ({ children }) => <li >{children}</li>,
    ol: ({ children }) => (
      <CustomList tag="ol" blockKey={`copyBlock-${useUID()}`} className={listClassname}>
        {children}
      </CustomList>
    ),
    h1: ({ children }) => <Heading className={paragraphClassList} level="1">{children}</Heading>,
    h2: ({ children }) => <Heading className={paragraphClassList} level="2">{children}</Heading>,
    h3: ({ children }) => <Heading className={paragraphClassList} level="3">{children}</Heading>,
    normal: ({ children }) => {
      const classes = `text-md ${paragraphClassList || ""} ${className}`;
      return Tag ? <Tag className={classes} >{children}</Tag> : <p className={classes}>{children}</p>;
    },
    fontOptions: ({
      children,
      paragraphSpacing,
      size,
      lineHeight,
      fontFamily,
      classname,
    }) => (
      <span
        style={{
          fontSize: size,
          lineHeight: lineHeight,
          marginBottom: paragraphSpacing,
          fontFamily: fontFamily,
        }}
        className={classname}
        
      >
        {children}
      </span>
    ),
    properties: ({
      id,
      classNames,
      customAttributes,
      children,
    }) => {
      const attributeList = customAttributes?.split(", ")
      const attributeObject = {}
      attributeList?.forEach(attr => {
        const [key, value] = attr.split("|")
        attributeObject[key] = value
      })

      return (
        <span
          id={id}
          className={classNames}
          {...attributeObject}
          
        >
          {children}
        </span>
      )
    },
    richTextHTML: ({ html }) => <div dangerouslySetInnerHTML={{ __html: html }} />,
    richTextImage: (image) => <Image image={image} className={"!h-fit rounded-xl overflow-hidden"} />
  }
  const containedContent = (
    <PortableText
      renderContainerOnSingleChild={renderContainerOnSingleChild}
      className={classList}
      content={text}
      serializers={serializers}
      
    />
  );

  if (childrenHTML) {
    return <div className={`${classList} ${paragraphClassList}`} dangerouslySetInnerHTML={createMarkup()} ></div>;
  }

  if (text) {
    return containedContent;
  }

  return <Tag className={`text-md max-w-lg mx-auto ${classList} ${paragraphClassList || ""}`} >{children}</Tag>;
};


export default RichText