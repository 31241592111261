export const desktopButtonWidth = {
  none: "",
  full: "md:w-full md:max-w-full",
  fit: "md:min-w-fit md:max-w-fit",
  fixed: "md:min-w-button md:max-w-button",
}

export const mobileButtonWidth = {
  none: "",
  full: "max-md:w-full max-md:max-w-full",
  fit: "max-md:min-w-fit max-md:max-w-fit",
  fixed: "max-md:min-w-full max-md:max-w-full",
}

export const setButtonTheme = theme => {
  if (theme && typeof theme == "string") {
    return {
      desktop: theme,
      mobile: theme,
    }
  }

  return theme
}

export const setButtonWidth = theme => {
  if (theme && typeof theme == "string") {
    return {
      desktopWidth: theme,
      mobileWidth: theme,
    }
  }

  return theme
}
