import React from 'react';
import useToggleStates from '@src/hooks/useToggleStates';
import { useSiteSettingsStore } from '@src/hooks/useStore';

const Backdrop = ({ zIndex }) => {
  const { siteSettings } = useSiteSettingsStore(['siteSettings']);

  const { backdropOpened } = siteSettings;

  const { toggleAllFunction } = useToggleStates();
  
  return (
    <div
      className={`top-0 transition-all duration-500 ${backdropOpened ? `bg-soft-black fixed opacity-40 top-0 left-0 w-full h-screen ${zIndex || 'z-40'}` : ''}`}
      onClick={() => toggleAllFunction()}
    ></div>
  );
}

export default Backdrop;
