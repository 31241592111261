import { getRegionCurrency } from "@src/utils/region"
import { checkShopifyId, getProductList, formatImageSrc, removeNullValues, checkCompareAtPrice, getProductCategory } from './helpers'
import { getPageTypeFromPath } from "@utils/gtm"

export const userPropertiesData = () => {
  return {
    user_consent: "",
    visitor_type: "guest"
  }
}

export const productsData = (eventName, products, pathname) => {  
  return products.map(({ attributes, merchandise, quantity, position, discountAllocations }, index) => {
    const itemPosition = position === null || !position ? index : position

    const {
      id: variantId,
      sku,
      title: variantTitle,
      price,
      compareAtPrice,
      image,
      product: {
        id: productId,
        title: productTitle,
        category,
        onlineStoreUrl: url,
        list,
      },
    } = merchandise

    return removeNullValues({
      id: sku,
      name: productTitle,
      brand: 'Diggs INC',
      category: getProductCategory(attributes, category),
      variant: variantTitle,
      price: price?.amount ? price?.amount : price || '0.0',
      quantity: ['dl_select_item','dl_view_item'].includes(eventName) ? null : quantity,
      position: eventName === 'dl_select_item' ? +itemPosition + 1 : null,
      list: getProductList(attributes, pathname || list),
      product_id: checkShopifyId(productId),
      variant_id: checkShopifyId(variantId),
      compare_at_price: checkCompareAtPrice(price, compareAtPrice),
      image: image?.src ? formatImageSrc(image.src) : null,
      url: eventName == 'dl_add_to_cart' ? url : null,
      discountAllocations,
    })
  })
}

export const impressionsData = (eventName, products) => {
  return products.map(({ attributes, merchandise, quantity , position}, index) => {
    const itemPosition = position === null || !position ? index : position
    const {
      id: variantId,
      sku,
      product: {
        id: productId,
        title: productTitle,
        category,
        onlineStoreUrl: url,
      },
      list,
      title: variantTitle,
      price,
      compareAtPrice,
    } = merchandise

    return removeNullValues({
      id: sku,
      name: productTitle,
      brand: 'Diggs INC',
      category: getProductCategory(attributes, category),
      variant: variantTitle,
      price: price?.amount ? price?.amount : price || '0.0',
      quantity: eventName === 'dl_view_cart' ? quantity : null,
      position: +itemPosition + 1 || 0,
      list: eventName === 'dl_view_cart' ? null : getProductList(attributes, list),
      product_id: checkShopifyId(productId),
      variant_id: checkShopifyId(variantId),
      compare_at_price: checkCompareAtPrice(price, compareAtPrice),
      url: url || null,
    })
  })
}

export const trackEvent = async (event, data) => {
  // console.log({
  //   event,
  //   ...data
  // });
  window.ElevarDataLayer.push({
    event,
    ...data
  })
}

export const trackEcommData = (data) => {
  return {
    ecommerce: {
      currencyCode: getRegionCurrency(),
      ...data
    }
  }
}

export const trackUserData = (cartInfos) => {
  trackEvent("dl_user_data", {
    cart_total: cartInfos?.cartTotal || '',
    user_properties: userPropertiesData(),
    ...trackEcommData({
      cart_contents: {
        products: cartInfos?.lines || []
      }
    })
  });
}

export const trackViewItemList = (products) => {
  trackEvent("dl_view_item_list", {
    user_properties: userPropertiesData(),
    ...trackEcommData({
      impressions: impressionsData('dl_view_item_list', products)
    })
  });
}

export const trackViewSearchResults = (products) => {
  trackEvent("dl_view_search_results", {
    user_properties: userPropertiesData(),
    ...trackEcommData({
      actionField: { 
        list: "search results"
      },
      impressions: impressionsData('dl_view_search_results', products)
    })
  });
}

export const trackSelectItem = (products, pathname) => {
  trackEvent("dl_select_item", {
    user_properties: userPropertiesData(),
    ...trackEcommData({
      click: {
        actionField: {
          list: pathname,
          action: "click"
        },
        products: productsData('dl_select_item', products, pathname)
      }
    })
  });
}

export const trackViewItem = (products, pathname) => {
  trackEvent("dl_view_item", {
    user_properties: userPropertiesData(),
    ...trackEcommData({
      detail: {
        actionField: {
          list: pathname,
          action: "detail"
        },
        products: productsData('dl_view_item', products, pathname)
      }
    })
  });
}

export const trackAddToCart = (products, pathname) => {
  trackEvent("dl_add_to_cart", {
    user_properties: userPropertiesData(),
    ...trackEcommData({
      add: {
        actionField: {
          list: pathname
        },
        products: productsData('dl_add_to_cart', products, pathname)
      }
    })
  });
}

export const trackRemoveFromCart = (products, pathname) => {
  trackEvent("dl_remove_from_cart", {
    user_properties: userPropertiesData(),
    ...trackEcommData({
      remove: {
        actionField: {
          list: pathname
        },
        products: productsData('dl_remove_from_cart', products, pathname)
      }
    })
  });
}

export const trackViewCart = (cart, products) => {
  trackEvent("dl_view_cart", {
    user_properties: userPropertiesData(),
    cart_total: cart?.cost?.totalAmount?.amount,
    ...trackEcommData({
      actionField: {
        list: 'Shopping Cart'
      },
      impressions: impressionsData('dl_view_cart', products)
    })
  });
}

export const trackSubscribe = (email) => {
  trackEvent('dl_subscribe', {
    lead_type: 'email',
    user_properties: {
      customer_email: email
    }
  })
}

export const getCJPageType = (type) => {
  const map = new Map([
    ["home", "homepage"],
    ["product", "productDetail"],
    ["category", "category"],
    ["other", undefined],
  ])

  return map.get(type)
}

export const mapCJCartItem = item => {
  const {
    id,
    name,
    brand,
    variant,
    price,
    quantity,
    list,
    product_id,
    variant_id,
    compare_at_price,
    discountAllocations = [],
    image,
  } = item
  const discount = discountAllocations
    .map(discount => parseFloat(discount.discountedAmount.amount || 0))
    .reduce((a, b) => a + b, 0)

  return {
    name: name,
    id: id,
    productId: product_id,
    variantId: variant_id,
    discount,
    sku: id,
    quantity,
    imageUrl: image,
    price: price,
    salePrice: compare_at_price,
    brand: brand,
    variant: variant,
  }
}

export const trackPageViewCJ = (cartInfos, pathname) => {
  const cjType = getCJPageType(getPageTypeFromPath(pathname))

  if (cjType) {
    const productMap = cartInfos?.lines?.map(mapCJCartItem) || []
    const cartCost = cartInfos?.cost || {}
    const lineItemsSubtotal = parseFloat(cartCost?.subtotalAmount?.amount) || 0
    const subtotal = parseFloat(cartCost?.totalAmount?.amount) || 0
    const discountTotal = Math.max(0, lineItemsSubtotal - subtotal)

    trackEvent("pageViewCJ", {
      cartTotal: cartInfos?.cartTotal || "",
      discountTotal,
      ecommerce: {
        currencyCode: cartInfos?.currencyCode || getRegionCurrency(),
        cj: {
          eventType: cjType,
          products: [...productMap],
        },
      },
    })
  }

  return null
}