import useGlobalStore from "@src/store"
import { useShallow } from "zustand/react/shallow"

const useStore = (properties, slice) =>
  properties?.length
    ? useGlobalStore(
        useShallow(state =>
          properties.reduce(
            (acc, property) => ({ ...acc, [property]: state[slice][property] }),
            {}
          )
        )
      )
    : useGlobalStore(useShallow(state => state[slice]))

export const useCartStore = properties =>
  useStore(properties, "cartStore")

export const useSiteSettingsStore = properties =>
  useStore(properties, "siteSettingsStore")

export const useSidebarStore = properties =>
  useStore(properties, "sidebarStore")

export const useSearchStore = properties =>
  useStore(properties, "searchStore");

export const useQuickViewStore = properties =>
  useStore(properties, "quickViewStore");

export const useProductStore = properties =>
  useStore(properties, "productStore");

export default useStore
