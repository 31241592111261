import { GraphQLClient } from "graphql-request"
import { getRegionCurrency, getRegionIsoCode, isVisibleIn } from "@utils/region"
import { getCheckoutAttributes } from "@src/analytics/visitorInfo"
import { productsData } from "@src/services/analytics"

import cartNodeQuery from "@context/graphql/cart/cartNodeQuery.graphql"
import cartCreateMutation from "@context/graphql/cart/cartCreateMutation.graphql"
import cartLinesAddMutation from "@context/graphql/cart/cartLinesAddMutation.graphql"
import cartLinesRemoveMutation from "@context/graphql/cart/cartLinesRemoveMutation.graphql"
import cartLinesUpdateMutation from "@context/graphql/cart/cartLinesUpdateMutation.graphql"
import cartDiscountCodesUpdateMutation from "@context/graphql/cart/cartDiscountCodesUpdateMutation.graphql"
import cartAttributesUpdateMutation from "@context/graphql/cart/cartAttributesUpdateMutation.graphql"
import { projection } from "@src/build/queries/projection"
import client from "@src/api/sanity/client"

const getCartKey = () => {
  const CART_KEY = "shopify_cart_id"
  const isBaseCurrency = getRegionCurrency() === "USD"

  return isBaseCurrency ? CART_KEY : `${CART_KEY}_${getRegionIsoCode()}`
}

const getGraphQLClient = () => {
  const storefrontAccessToken = process.env.SHP_TOKEN
  const domain = process.env.SHOP_DOMAIN
  const apiVersion = process.env.SHOP_API_VERSION || "2023-07"

  const shopifyStorefrontEndpoint = `https://${domain}/api/${apiVersion}/graphql`
  const headers = {
    accept: "application/json",
    "X-SDK-Variant": "javascript",
    "X-Shopify-Storefront-Access-Token": storefrontAccessToken,
    "Accept-Language": "*",
  }

  const graphQLClient = new GraphQLClient(shopifyStorefrontEndpoint, {
    headers,
  })

  return graphQLClient
}

const graphQLClient = getGraphQLClient()

const replaceContextCountry = query => {
  query.definitions.find(
    definition => definition.kind == "OperationDefinition"
  ).directives[0].arguments[0].value.value = getRegionIsoCode()

  return query
}

const fixCartLineEdges = cart => {
  return {
    ...cart,
    lines: cart.lines.edges.map(edge => {
      return {
        ...edge.node,
      }
    }),
  }
}

const isEmpty = input => {
  return (
    !input || input.length === 0 || input === "null" || input === "undefined"
  )
}

const setCartInfos = (cart) => {
  const cartLines = cart.lines.map(line => ({
    ...line,
    merchandise: {
      ...line.merchandise,
      product: {
        ...line.merchandise.product,
        list: line.attributes?.find(attribute => attribute.key === "_list")
          ?.value,
        category: line.attributes?.find(
          attribute => attribute.key === "_category"
        )?.value,
      },
    },
  }))

  localStorage.setItem(
    "cartInfos",
    JSON.stringify({
      ...cart,
      cartTotal: cart?.cost?.totalAmount?.amount || "0.0",
      lines: productsData("dl_user_data", cartLines) || {},
    })
  )
}

const createNewCart = async (set, get) => {
  try {
    const response = await graphQLClient
      .request(replaceContextCountry(cartCreateMutation), {
        input: {
          buyerIdentity: {
            countryCode: getRegionIsoCode(),
          },
          attributes: getCheckoutAttributes(),
        },
      })
      .then(data => {
        return data
      })

    const newCart = fixCartLineEdges(response?.cartCreate?.cart)

    localStorage.setItem(getCartKey(), newCart?.id)

    set((state) => ({
      cartStore: {
        ...state.cartStore,
        cart: {
          ...state.cartStore.cart,
          ...newCart,
        },
      }
    }));

    setCartInfos(newCart);
    fetchBundleProducts(set, newCart);
    await setUpsells(set, get)

    return null
  } catch (e) {
    console.log("Error creating new cart", e)

    localStorage.setItem(getCartKey(), null)

    return null
  }
}

export const fetchCart = async (set, get, cartId) => {
  try {
    const response = await graphQLClient
      .request(replaceContextCountry(cartNodeQuery), {
        id: cartId,
      })

    const cart = fixCartLineEdges(response?.cart)

    set((state) => ({
      cartStore: {
        ...state.cartStore,
        cart: {
          ...state.cartStore.cart,
          ...cart,
        },
      }
    }));

    setCartInfos(cart)
    fetchBundleProducts(set, cart);
    updateCartAttributes(set, cartId)
    await setUpsells(set, get)

    return cart
  } catch (e) {
    console.log("Error fetching cart", e)

    await createNewCart()

    return null
  }
}

export const fetchOrCreateCart = async (set, get) => {
  const isBrowser = typeof window !== "undefined"
  const existingCartID = isBrowser ? localStorage.getItem(getCartKey()) : null

  if (!isEmpty(existingCartID)) {
    await fetchCart(set, get, existingCartID)
  } else {
    await createNewCart(set, get)
  }
}

const updateCartAttributes = async (set, cartId, attributes) => {
  try {
    const response = await graphQLClient
      .request(cartAttributesUpdateMutation, {
        cartId,
        attributes: attributes || getCheckoutAttributes(),
      })

    const updatedCart = fixCartLineEdges(response?.cartAttributesUpdate?.cart)

    set((state) => ({
      cartStore: {
        ...state.cartStore,
        cart: {
          ...state.cartStore.cart,
          ...updatedCart,
        },
      }
    }));

    return updatedCart
  } catch (e) {
    console.log("Error updating cart attributes", e)
    return null
  }
}

const fetchBundleProducts = async (set, cart) => {
  const url = "https://www.diggs.pet/bundle-products.json"
  const { id, lines } = cart
  const addedBundles = lines
    ?.map(
      line =>
        line?.attributes?.find(attribute => attribute.key === "_bundle")?.value
    )
    .filter((value, index, self) => self.indexOf(value) === index)

  await fetch(url)
    .then(res => res.json())
    .then(res => {
      if (res) {
        const filteredResults = res?.filter(result =>
          addedBundles?.includes(result?.i)
        )

        updateCartAttributes(set, id, {
          key: "_bundles",
          value: JSON.stringify(filteredResults),
        })

        return res
      }
    })
    .catch(e => {
      console.log(e)
    })
}

export const processLineItems = (lines) => {
  return lines.map((line) => ({
    ...line,
    quantity: parseInt(line.quantity, 10),
    attributes: [
      ...Object.keys(line.attributes).map((key) => ({
        key,
        value: line.attributes[key],
      })),
      {
        key: "_quantity",
        value: String(line.quantity),
      },
    ]
  }));
};

export const addLineItemsToCart = async (cartId, lines) => {
  try {
    const response = await graphQLClient
      .request(replaceContextCountry(cartLinesAddMutation), {
        cartId,
        lines,
      });

    const updatedCart = fixCartLineEdges(response?.cartLinesAdd?.cart);

    return updatedCart;
  } catch (e) {
    console.log('Error adding line items to cart:', e);
    return null
  }
};

export const removeLineItemsFromCart = async (cartId, lineIds) => {
  try {
    const response = await graphQLClient.request(cartLinesRemoveMutation, {
      cartId,
      lineIds,
    });

    const updatedCart = fixCartLineEdges(response?.cartLinesRemove?.cart);
    return updatedCart;
  } catch (error) {
    console.log('Error removing line items from cart:', error);
    return null
  }
};

export const updateLineItemsInCart = async (cartId, lines) => {
  try {
    const response = await graphQLClient.request(cartLinesUpdateMutation, {
      cartId,
      lines,
    });

    const updatedCart = fixCartLineEdges(response?.cartLinesUpdate?.cart);
    return updatedCart;
  } catch (error) {
    console.log('Error updating line items in cart:', error);
    return null
  }
};

export const updateDiscount = async (cartId, discountCodes) => {
  try {
    const response = await graphQLClient.request(cartDiscountCodesUpdateMutation, {
      cartId,
      ...(discountCodes && discountCodes.length > 0 && { discountCodes: [discountCodes] }),
    });

    const updatedCart = fixCartLineEdges(response?.cartDiscountCodesUpdate?.cart);

    if (response?.cartDiscountCodesUpdate?.userErrors?.length !== 0) {
      return {
        updatedCart,
        userErrors: response.cartDiscountCodesUpdate.userErrors,
      };
    }

    return { updatedCart, userErrors: null };
  } catch (error) {
    console.log('Error adding discount code:', error);
    return null
  }
}

export const setUpsells = async (set, get) => {
  const lines = get().cartStore.cart.lines;
  const { youMightAlsoLike } = projection

  if (lines.length) {
    const lineIds = lines.map(line => `"${line?.merchandise?.product?.id?.match(/[^\/]+$/)[0]}"`)

    // Recommended Products
    await client.fetch(`*[_type == "shopifyProduct" && _id in [${lineIds}]] {
      youMightAlsoLike[]->{
        ${youMightAlsoLike}
      },
    }`).then(res => {
      const newUpsells = res?.flatMap(prod => prod.youMightAlsoLike)

      set((state) => ({
        cartStore: {
          ...state.cartStore,
          upsells: newUpsells
            ?.filter(upsell => upsell) // filter out null values
            ?.filter((upsell, index) => index === newUpsells.findIndex(_upsell => _upsell._id === upsell._id)) // filter out same upsells
            ?.filter(upsell => !lines?.find(line => upsell?._id === line?.merchandise?.product?.id?.match(/[^\/]+$/)[0]))
        }
      }));
    })

    // Under 50 Products
    await client.fetch(`*[_type == "shopifyProduct" && enabled == true && count(variants) > 0] | order(orderRank) {
      ${youMightAlsoLike}
    }`).then(res => {
      const newUpsells = res?.map(product => ({
        ...product,
        variants: product.variants.filter(variant => 
          Number(variant.price) < 50 &&
          isVisibleIn(variant?.visibilityRegions) &&
          !variant?.hide
        )
      }))

      set((state) => ({
        cartStore: {
          ...state.cartStore,
          under50: newUpsells
            ?.filter(upsell => upsell) // filter out null values
            ?.filter(product => 
              Number(product.priceFrom) < 50 &&
              isVisibleIn(product?.visibilityRegions)
            )
            ?.filter(upsell => !lines?.find(line => upsell?._id === line?.merchandise?.product?.id?.match(/[^\/]+$/)[0]))
        }
      }));
    })
  } else {
    set((state) => ({
      cartStore: {
        ...state.cartStore,
        upsells: [],
        under50: [],
      }
    }));
  }
}