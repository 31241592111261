export const setSiteSettings = (set, settings) => {
  set(state => ({
    siteSettingsStore: {
      ...state.siteSettingsStore,
      siteSettings: {
        ...state.siteSettingsStore.siteSettings,
        ...settings,
      },
    },
  }))
}

export const setAlert = (set, alert) => {
  set(state => ({
    siteSettingsStore: {
      ...state.siteSettingsStore,
      alert: alert,
    },
  }))
}

export const toggleBackdrop = (set, override = null) => {
  set(state => ({
    siteSettingsStore: {
      ...state.siteSettingsStore,
      siteSettings: {
        ...state.siteSettingsStore.siteSettings,
        backdropOpened: override
          ? override
          : !state.siteSettingsStore.siteSettings.backdropOpened,
      },
    },
  }))
}
