import React, { useCallback, useState } from 'react';
import { Link, navigate } from 'gatsby';
import SidebarAccordion from '../accordion';
import { useIsMobile } from '@utils/general';
import { linkProps } from '@utils/general';
import CountryPicker from '@components/country-picker';
import {ReactComponent as Arrow} from '@svgs/arrow.svg'
import {ReactComponent as ArrowDown} from '@svgs/arrow-down.svg'
import { getUriFromDocument } from '@src/utils/routing';
import Image from '@src/components/image';
import Button from '@src/components/button';
import Collapsible from "react-collapsible";
import useToggleStates from '@src/hooks/useToggleStates';

const SidebarContent = ({ children, className }) => {
	return (
		<div className={`flex flex-col h-full overflow-y-auto sidebar-content md:py-5 ${className || ''}`} >
			{children}
		</div>
	);
};

export default SidebarContent;

export const Navigation = ({ nodes }) => {
	const [openAccordionIndex, setOpenAccordionIndex] = React.useState('header');
	const isMobile = useIsMobile();

	const {toggleAllFunction} = useToggleStates();

	const toggleSidebar = () => {
		toggleAllFunction();
	}

	const handleAccordionClick = (index) => {
		setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
	};

	const headerNav = nodes?.filter(item => item.group === "header")
	const sidebarNav = nodes?.filter(item => item.group === "secondaryHeader")
	return (
		<>
		{headerNav && 
			<CountryPicker className="order-last md:order-none xl:hidden mx-10" />}
		{isMobile && headerNav && (
			<SidebarAccordion
				heading='Collections'
				style={{
					wrapper: 'md:hidden'
				}}
				opened={openAccordionIndex === 'header'}
				onAccordionClick={() => handleAccordionClick('header')}
				isAccordion={{
					desktop: false,
					mobile: true
				}}
				hasIcon={false}
			>
				{headerNav.map((nav, index) => {
					const { title, internalLink, externalUrl, childNodes } = nav;
					
					return (
						<li key={index} className='w-full cursor-pointer'>
							{childNodes?.length > 0 
								? (
									<MobileHeaderLinks {...{title, childNodes, toggleSidebar, openAccordionIndex}} />
								) : (
									<Link
										{...linkProps(internalLink, externalUrl)}
										className='text-sidebar-header-content leading-none font-normal font-owners w-full cursor-pointer flex justify-between items-center'
										onClick={() => toggleSidebar()}
										tabIndex={openAccordionIndex === 'header' ? 0 : -1}
										role={'listitem'}
									>
										{title}
										{childNodes?.length > 0 && <ArrowDown className="fill-black group-hover/item:fill-safety-green transition-all duration-300" />}
									</Link>
								)}
						</li>
					)
				}
				)}
			</SidebarAccordion>
		)}

		{sidebarNav.map(({ title, childNodes }, index) => (
			<SidebarAccordion
				key={index}
				heading={title}
				opened={openAccordionIndex === index}
				onAccordionClick={() => handleAccordionClick(index)}
				isAccordion={{
					desktop: false,
					mobile: true
				}}
			>
				{childNodes.map(({ title, internalLink, externalUrl }, _index) => (
					<li key={_index}>
						<Link
							key={_index}
							{...linkProps(internalLink, externalUrl)}
							title={title}
							className='subquote text-base md:text-lg leading-none max-w-fit'
							onClick={() => toggleSidebar()}
							tabIndex={isMobile && openAccordionIndex !== index ? -1 : 0}
						>
							{title}
						</Link>
					</li>
				)
				)}
			</SidebarAccordion>
		)
		)}
		</>
	)
}


const MobileHeaderLinks = ({ title, childNodes, toggleSidebar, openAccordionIndex }) => {
	const [opened, setOpened] = useState(false);

	const handleOpen = useCallback(() => {
    setOpened(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

	return (
		<Collapsible
			transitionTime="200"
			triggerTagName='div'
			onOpen={handleOpen}
			onClose={handleClose}
			triggerElementProps={{
				className: 'text-sidebar-header-content leading-none font-normal font-owners w-full cursor-pointer flex justify-between items-center',
				tabIndex: openAccordionIndex === 'header' ? 0 : -1,
				role: 'listitem',
				"aria-label": title + ", Dropdown"
			}}
			trigger={(
				<>
					{title}
					{childNodes?.length > 0 && <ArrowDown className="fill-black group-hover/item:fill-safety-green transition-all duration-300" />}
				</>
			)}
		>
			<ul className='flex flex-col max-h-fit pt-2.5'>
				{childNodes?.map((child, index) => (
					<li key={index} className='group/item border-t first:border-t-0 border-darker-gray' >
						{child?.products?.length > 0 
						? (
							<MobileHeaderInnerLinks {...{child, toggleSidebar, parentOpened: opened}} />
						) : (
							<Link 
								{...linkProps(child.internalLink, child.externalUrl)}
								className='text-sm leading-none py-2.5 font-medium flex gap-x-1 group-hover/item:text-safety-green transition-all duration-300 cursor-pointer w-full justify-between'
								onClick={() => toggleSidebar()}
								tabIndex={opened ? 0 : -1}
								aria-hidden={!opened}
							>
								{child.title}
								<Arrow className="w-4 group-hover/item:fill-safety-green" />
							</Link>
						)}
					</li>
				))}
			</ul>
		</Collapsible>
	)
}


const MobileHeaderInnerLinks = ({ child, toggleSidebar, parentOpened }) => {
	const [opened, setOpened] = useState(false);

	const handleOpen = useCallback(() => {
    setOpened(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

	return (
		<Collapsible
			tabIndex={parentOpened ? 0 : -1}
			contentEditable={true}
			transitionTime="200"
			triggerTagName='p'
			onOpen={handleOpen}
			onClose={handleClose}
			triggerElementProps={{
				className: 'text-sm leading-none py-2.5 font-medium flex gap-x-1 group-hover/item:text-safety-green transition-all duration-300 cursor-pointer w-full justify-between',
				"aria-label": child.title + ", Dropdown"
			}}
			trigger={(
				<>
					{child.title}
					<ArrowDown className="fill-black group-hover/item:fill-safety-green transition-all duration-300" />
				</>
			)}
		>
			<div className='flex flex-col gap-x-5 gap-y-4 max-h-fit py-2.5'>
				{child?.products?.map((product, index) => (
					<Link to={getUriFromDocument(product)} key={index} aria-hidden={!opened} tabIndex={opened ? 0 : -1} className="w-full" onClick={() => toggleSidebar()} aria-label={`Product: ${product?.shortTitle || product?.title}`}>
						<div
							className={`flex min-w-full gap-x-2.5 border-1 border-solid border-darker-gray rounded-xl overflow-hidden p-2`}
						>
							<div className=" md:max-w-20 max-w-1/3 aspect-[4/3] flex">
								<Image image={product?.mainImage} className="rounded-xl" />
							</div>
							<div className="max-w-full w-full flex flex-col justify-center gap-y-2.5">
								<span className={`text-inherit w-fit font-medium !inline`}>
									{product?.shortTitle || product?.title}
									{' - '}
									<span className='text-darker-gray font-normal'>
										{product?.subtitle}
									</span>
								</span>
							</div>
						</div>
					</Link>
				))}
				
				{child?.products?.length > 0 && (
					<Button theme="btn-transparent" aria-hidden={!opened} tabIndex={opened ? 0 : -1} onClick={() => { toggleSidebar(); navigate(getUriFromDocument(child.internalLink))}} ctaWidth={"full"}>
						View All Products
					</Button>
				)}
			</div>
		</Collapsible>
	)
}