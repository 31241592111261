import React, { useRef, useState } from 'react'
import { Link } from "gatsby"
import { linkProps, useIsMobile } from '@utils/general';
import {ReactComponent as Arrow} from '@svgs/arrow.svg'
import {ReactComponent as ArrowDown} from '@svgs/arrow-down.svg'
import Image from '../image';
import { getUriFromDocument } from '@src/utils/routing';
import Button from '../button';

export const NavMenu = ({ data, className }) => {
  return (
    <nav aria-label='Main Menu' className={className}>
      <ul className='flex gap-x-5 lg:gap-x-7 gap-y-2 flex-wrap items-center justify-center'>
        {data?.map((nav, index) => <NavMenuItem key={index} nav={nav} index={index} />)}
      </ul>
    </nav>
  )
}

const NavMenuItem = ({ nav, index }) => {
  const { title, internalLink, externalLink, childNodes, hasProducts } = nav

  const isMobile = useIsMobile();

  const [opened, setOpened] = useState(false);
  const hoverTimeout = useRef(null);

  const handleMouseEnter = () => {
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
      hoverTimeout.current = null;
    }
    setOpened(true);
  };

  const handleMouseLeave = (event) => {
    const currentTarget = event.currentTarget;
    hoverTimeout.current = setTimeout(() => {
      if (!currentTarget.contains(event.relatedTarget)) {
        setOpened(false);
      }
    }, 400);
  };

  if (childNodes?.length > 0) {
    return (
      <li 
        key={React.useId()}
        className='group'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleMouseEnter}
        onBlur={handleMouseLeave}
      >
        {internalLink || externalLink 
          ? (
            <Link 
              {...linkProps(internalLink, externalLink)} 
              title={title} 
              className='text-lg leading-none font-normal flex gap-x-1 group-hover:text-safety-green transition-all duration-300 cursor-pointer'
            >
              {title}
              <ArrowDown className="fill-black group-hover:fill-safety-green transition-all duration-300" />
            </Link>
          ) : (
            <p className='text-lg leading-none font-normal flex gap-x-1 group-hover:text-safety-green transition-all duration-300 cursor-pointer'>
              {title}
              <ArrowDown className="fill-black group-hover:fill-safety-green transition-all duration-300" />
            </p>
          )
        }
        
        {!isMobile && opened && <InnerNavMenu handleMouseLeave={handleMouseLeave} handleMouseEnter={handleMouseEnter} title={title} childs={childNodes} hasProducts={hasProducts} />}
      </li>
    )
  }

  return (
    <li key={React.useId()}>
      <Link {...linkProps(internalLink, externalLink)} title={title}>
        {title}
      </Link>
    </li>
  )
}

export const InnerNavMenu = ({ title, childs, hasProducts, handleMouseEnter, handleMouseLeave }) => {
  const [activeElement, setActiveElement] = useState(childs[0] || null);

  return (
    <div 
      className={`block transition-all absolute top-full ${hasProducts ? 'max-w-4xl' : 'max-w-xl'} w-full left-1/2 -translate-x-1/2 `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* <button className='sr-only'>{`Skip Dropdown for ${title}`}</button> */}
      <div className='pt-5'>
        <div className='bg-white p-5 rounded-xl grid grid-cols-12 gap-x-5'>
          <nav aria-label={`${title} Menu`} className='col-span-5 flex flex-col gap-y-2.5'>
            <p className='text-2xl leading-none font-medium'>{title}</p>
            <ul className='flex flex-col'>
              {childs?.map((child, index) => (
                <Link 
                  key={`inner-nav-item-${index}`} 
                  className='flex justify-between relative py-2.5 text-lg font-medium border-b last:border-b-0 border-darker-gray cursor-pointer group/item hover:text-safety-green transition-all duration-300'
                  onMouseEnter={() => child.title !== activeElement.title && setActiveElement(child)}
                  title={child.title}
                  aria-label={child.title || "Navigation Link"}
                  {...linkProps(child.internalLink, child.externalLink)}
                >
                  {child.title}
                  <Arrow className="w-4 absolute right-2.5 top-1/2 -translate-y-1/2 group-hover/item:fill-safety-green transition-all duration-300" />
                </Link>   
              ))}
            </ul>
          </nav>
          

          <div className='col-span-7 flex flex-col gap-y-5'>
            <div className={`relative ${activeElement?.products?.length > 0 && hasProducts ? 'h-[140px]' : 'h-full'} rounded-xl overflow-hidden flex items-center justify-center`}>
              <Image 
                image={activeElement.navImage} 
                className={'!absolute top-0 w-full h-full'} 
                alt={activeElement?.navImage?.asset?.alt}
                title={activeElement?.navImage?.asset?.alt}
              />
              <div className='bg-black opacity-20 w-full h-full absolute top-0 z-[5]'></div>
              <div className='text-2xl leading-none z-10 relative text-center font-medium text-white'>{activeElement.title}</div>
            </div>

            {hasProducts && (
              <div className='grid grid-cols-12 gap-x-5 gap-y-4'>
                {activeElement?.products?.map((product, index) => (
                  <Link to={getUriFromDocument(product)} key={index} className="w-full col-span-6" aria-label={`Product: ${product?.shortTitle || product?.title} - ${product?.subtitle}`}>
                    <div
                      className={`flex min-w-full gap-x-2.5 border-1 border-solid border-darker-gray rounded-xl overflow-hidden p-2`}
                    >
                      <div className=" md:max-w-20 max-w-1/3 aspect-[4/3] flex">
                        <Image image={product?.mainImage} className="rounded-xl" />
                      </div>
                      <div className="max-w-full w-full flex flex-col justify-center gap-y-2.5">
                        <span className={`text-inherit w-fit font-medium !inline`}>
                          {product?.shortTitle || product?.title}
                          {' - '}
                          <span className='text-darker-gray font-normal'>
                            {product?.subtitle}
                          </span>
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
                
                {activeElement?.products?.length > 0 && (
                  <Button theme="btn-transparent col-span-6" internalLink={getUriFromDocument(activeElement.internalLink)} ctaWidth={"full"}>
                    View All Products
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const appendProductsToGroup = (childs, products, hasProducts) => {
  if (!hasProducts) return childs;

  const mappedChilds = childs.map(child => {
    const { internalLink: { _id, _type } } = child;

    if (_type === 'productGroup') {
      child.products = products?.filter(product => product.productGroups.find(group => group._id === _id)).slice(0, 3);
    } else if (_id === "productsIndex") {
      child.products = products?.slice(0, 3);
    }

    return child;
  });

  return mappedChilds;
}
