import React from "react"
import { NavMenu } from "../data"
import SearchToggle from '@components/search/search-toggle';
import CartToggle from "@components/cart/cart-toggle"
import HamburgerMenu from "@svgs/hamburger-menu"
import Logo from "@components/logo"
import Container from "@components/container"
import CountryPicker from "@components/country-picker";

const DesktopHeader = ({ data, onHamburgerClick }) => (
  <Container>
    <div className="w-full hidden md:grid grid-cols-12 bg-white py-3 px-5 rounded-xl mb-5 mt-15 mx-5 subquote z-[51] items-center relative">
      <div className="flex justify-start items-center gap-x-5 col-span-2">
        <Logo className="flex align-middle h-5 min-w-[70px]" />
        <SearchToggle className="max-w-fit" />
      </div>
      <NavMenu data={data} className="col-span-8" />
      <div className="col-span-2 relative flex items-center justify-end gap-x-5">
        <CountryPicker className="hidden xl:flex xl:-mt-[14px]" />
        <CartToggle />
        <HamburgerMenu onClick={onHamburgerClick} />
      </div>
    </div>
  </Container>
)

export default DesktopHeader