import { toggleBodyScrollLock } from "@src/utils/general"
import {
  getSearchCookie,
  setSearchCookie,
} from "@src/utils/search"

export const toggleSearch = (set, override) => {
  set(state => {
    const newIsOpen = override !== null ? override : !state.searchStore.isOpen

    if (newIsOpen !== state.searchStore.isOpen) {
      toggleBodyScrollLock("body", newIsOpen)
      return {
        searchStore: {
          ...state.searchStore,
          isOpen: newIsOpen,
          query: "",
          isFocused: false,
        },
      }
    }

    return state
  })
}

export const onSubmit = (set, { search }) => {
  setSearchCookie(search)
  set(state => {
    const latestQueries = getSearchCookie()
    if (
      search !== state.searchStore.query ||
      latestQueries !== state.searchStore.latestQueries
    ) {
      return {
        searchStore: {
          ...state.searchStore,
          query: search,
          latestQueries: latestQueries,
        },
      }
    }
    return state
  })
}

export const clearSearch = (set, setValue) => {
  setValue("search", "")

  set(state => {
    if (state.searchStore.query !== "") {
      return {
        searchStore: {
          ...state.searchStore,
          query: "",
        },
      }
    }
    return state
  })
}

export const onInputFocus = (set) => {
  set(state => {
    if (!state.searchStore.isFocused) {
      return {
        searchStore: {
          ...state.searchStore,
          isFocused: true,
        },
      }
    }
    return state
  })
}

export const setSearchState = (set, newState) => {
  set(state => ({
    searchStore: {
      ...state.searchStore,
      ...newState,
    },
  }))
}
