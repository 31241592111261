import React, { useState, useRef } from "react"
import Button from "../button";
import { useCartStore } from "@src/hooks/useStore";

const PromoCodeInput = ({ error, onSubmit }) => {
    const inputRef = useRef(null)
    const [code, setCode] = useState("")

    const { loading } = useCartStore(["loading"])
  
    const handleSubmit = e => {
      e.preventDefault()
  
      onSubmit(code)
    }
  
    const ErrorMessage = () => (
      <p className="p-0 m-0 mt-2 text-xs text-black">
        { error }
      </p>
    )

    return (
      <div className="overflow-x-hidden">
        <form className={'flex gap-x-2.5'} onSubmit={handleSubmit}>
          <div className="flex w-full border-1 rounded-xl">
          <input
              type="text"
              id="promoCode"
              name="promoCode"
              placeholder="Promo code:"
              className={`w-full uppercase text-base placeholder:capitalize placeholder:text-black placeholder:font-medium pl-5 rounded-xl`}
              value={code}
              onChange={e => setCode(e.target.value)}
              ref={inputRef}
              aria-label='Promo Code Input'
              disabled={loading}
            />
            </div>
          <Button 
            theme="btn-secondary" 
            aria-label='Apply Button'
            arrow='none'
            buttonType='submit'
            ariaLabel={'Apply Promo Code'}
            title={'Apply Promo Code'}
            disabled={loading}
          >
            Apply
          </Button>
        </form>
        { error && <ErrorMessage /> }
      </div>
    )
  }
  
  export default PromoCodeInput