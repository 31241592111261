import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { useUID } from "react-uid"
import { linkProps } from '@utils/general'
import { PlainLink } from "@components/link"

export const LinkSection = ({ title, links }) => {
  const uid = useUID()

  return (
    <div key={uid}>
      <p className="heading text-left text-base md:text-2xl font-medium tracking-tight text-soft-black">
        {title}
      </p>
      <ul className="mt-2">
        {links.map(({ title, externalUrl, internalLink, href }, index) => {

          return (
            (externalUrl || internalLink || href) && (
              <li
                key={`footerLinks-${uid}-${index}`}
                className="text-left mt-1"
              >
                <PlainLink
                  {...linkProps(internalLink, externalUrl, href)}
                  title={title}
                  className="text-base md:text-lg leading-4 font-normal text-soft-black inline-block"
                >
                  {title}
                </PlainLink>
              </li>
            )
          )
        })}
      </ul>
    </div>
  )
}

LinkSection.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
}


export const PrivacyLinks = ({ links }) => (
    <div className="bg-light-gray w-full py-4 px-5 md:py-5 md:px-0 grid grid-cols-2 gap-y-3 md:flex md:justify-center rounded-t-xl md:space-x-5">
      <p className="text-xs md:text-base inline">
        &copy; {new Date().getFullYear()} Diggs Inc.
      </p>
      {links.map(({ title, externalUrl, internalLink, _id }) => (
          <Link 
            key={`below-footer-link-${_id}`} 
            {...linkProps(internalLink, externalUrl)} 
            title={title} 
            className="text-xs md:text-base text-soft-black"
          >
            {title}
          </Link>
        )
      )}
    </div>
  )