import React, { useMemo } from "react";
import { uid } from "react-uid";
import { filteredAndSortedOptions, formatProductOptions } from "@utils/product";
import { ReactComponent as PrimeIcon } from "@svgs/buy-with-prime.svg";
import VariantChips from "./variant-chips";

const DEFAULT_TITLE_NAME = "Title";

const OptionSelector = ({
  currentVariant,
  productOptions,
  variants,
  handleOptionChange,
  hideInStickyATC,
}) => {
  const options = formatProductOptions(productOptions)
  
  const filteredOptions = useMemo(() => filteredAndSortedOptions(options, variants), [
    options,
    variants,
  ]);

  const isDefaultVariant = filteredOptions?.find(option => option.name === DEFAULT_TITLE_NAME);
  if (isDefaultVariant) return null;

  return (
    <div className="option-selector flex flex-col gap-y-2.5 md:gap-y-5 is-sticky-pt-0 relative top-0">
      {filteredOptions?.map((filteredOption, index) => (
        <OptionItem
          index={index}
          key={uid(filteredOption)}
          filteredOption={filteredOption}
          currentVariant={currentVariant}
          productOptions={productOptions}
          variants={variants}
          handleOptionChange={handleOptionChange}
          hideInStickyATC={hideInStickyATC}
        />
      ))}
    </div>
  );
}

const OptionItem = ({
  filteredOption,
  currentVariant,
  productOptions,
  variants,
  handleOptionChange,
}) => {
  const { name } = filteredOption;
  const isDefaultVariant = name === DEFAULT_TITLE_NAME;
  if (isDefaultVariant) return null;

  const optionLabels = (isOnSale, isUnavailable, isAvailableWithPrime) => {
    if (isUnavailable) {
      if (isAvailableWithPrime && isOnSale) {
        return (
          <>
            <div className="px-1.5 py-0.5 w-fit bg-white rounded-xl justify-center items-center gap-2 flex" aria-hidden={true}>
              <PrimeIcon />
            </div>
            <div className="px-1.5 py-0.5 w-fit bg-steadfast-red rounded-xl justify-center items-center gap-2 flex" aria-hidden={true}>
              <div className="text-right text-white text-xs font-normal whitespace-nowrap">Sale</div>
            </div>
          </>
        );
      } else if (isAvailableWithPrime) {
        return (
          <div className="px-1.5 py-0.5 w-fit bg-white rounded-xl justify-center items-center gap-2 flex" aria-hidden={true}>
            <PrimeIcon />
          </div>
        );
      } else {
        return (
          <div className="px-1.5 py-0.5 w-fit bg-[#F3F3F4] rounded-xl justify-center items-center gap-2 flex" aria-hidden={true}>
            <div className="text-right text-soft-black text-xs font-normal whitespace-nowrap">Out of Stock</div>
          </div>
        );
      }
    } else if (isOnSale) {
      return (
        <div className="px-1.5 py-0.5 w-fit bg-steadfast-red rounded-xl justify-center items-center gap-2 flex" aria-hidden={true}>
          <div className="text-right text-white text-xs font-normal whitespace-nowrap">Sale</div>
        </div>
      );
    }

    return null; // No label to show
  }

  return (  
    <div className="w-full chips-holder">
      <VariantChips 
        option={filteredOption}
        variants={variants}
        currentVariant={currentVariant}
        productOptions={productOptions}
        optionLabels={optionLabels}
        handleOptionChange={(index, value) => handleOptionChange(index, value)}
      />
    </div>
  );
};

export default OptionSelector;