import { setQuickViewOpen, setQuickViewType, setQuickViewProduct, setQuickViewVariant, setQuickViewProductList, setUnder50Upsell } from "@src/actions/quickView";

const initialState = {
    open: false,
    type: 'details',
    quickViewProduct: null,
    quickViewVariant: null,
    addedCrossSellProducts: [],
    quickViewProductList: null,
    isUnder50Upsell: false,
}

const quickViewSlice = (set) => ({
  ...initialState,

  setQuickViewOpen: (open) => setQuickViewOpen(set, open),

  setQuickViewType: (type) => setQuickViewType(set, type),

  setQuickViewProduct: (product) => setQuickViewProduct(set, product),

  setQuickViewVariant: (variant) => setQuickViewVariant(set, variant),

  setQuickViewProductList: (productList) => setQuickViewProductList(set, productList),

  setIsUnder50Upsell: (isUnder50) => setUnder50Upsell(set, isUnder50),
})

export default quickViewSlice;

