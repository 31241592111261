import React, { useEffect } from "react"
import UpsellItem from './item'
import Slider from 'react-slick'
import { ReactComponent as ArrowDown } from "@svgs/arrow-down.svg";
import { useCartStore } from "@src/hooks/useStore";
import { trackEvent } from "@src/services/analytics"

interface SliderArrowComponentProps {
  rotate?: boolean
  onClick?: () => void
  onOtherClick?: () => void
  customClassName?: string
}

export const SliderArrowComponent = ({ rotate = false, onClick, customClassName, onOtherClick }: SliderArrowComponentProps) => {
	const classList = `absolute z-10 p-2 right-[10px] bg-soft-black flex justify-end top-1/4 -translate-y-1/4 cursor-pointer rounded-md ${rotate ? 'right-[45px]' : ''} ${customClassName || ""}`
	const onArrowClick = () => {
    onClick && onClick();
    onOtherClick && onOtherClick();
  }
  return (
		<button onClick={onArrowClick} className={classList} tabIndex={0} aria-label={rotate ? 'Previous Upsell Product' : 'Next Upsell Product'} role={'button'}>
      <ArrowDown className={`fill-white ${rotate ? 'rotate-90' : 'rotate-[270deg] scale-x-[-1]'}`} />
		</button>
	)
}

// <<<<<<< HEAD
export default function CartUpsell({ type }: any) {
  const { upsells, under50 } = useCartStore(["upsells", "under50"]);

  if (!upsells || upsells.length === 0) return <></>

  const data = type === "under50" ? under50 : upsells;
// =======
// export default function CartUpsell({ type }: any) {
//   const lineItems = useLineItems()
//   const queryData = useStaticQuery(graphql`
//     query CartUpsellQuery {
//       klaviyo: allSanityKlaviyo(limit: 1) {
//         nodes {
//           unreleasedProducts {
//             shopifyId,
//             variantTitle,
//           }
//           backInStockButtonText
//           unreleasedProductButtonText
//         }
//       }
//       under50: allSanityShopifyProduct(sort:{fields: orderRank}) {
//         nodes {
//           _id
//           _type
//           title
//           shopifyId
//           yotpoProductId
//           visibilityRegions
//           priceFrom
//           summary
//           slug {
//             current
//           }
//           enabled
//           productGroups {
//             title
//             _type
//             slug {
//               current
//             }
//           }
//           productOptions: _rawProductOptions
//           variants {
//             variantTitle
//             listingVariantTitle
//             sku
//             size
//             color
//             flavor
//             configuration
//             price
//             shopifyId
//             shopifyIdBase64Encoded
//             variantComponents {
//               shopifyIdBase64Encoded
//               price
//               compareAtPrice
//               sku
//               variantTitle
//               status {
//                 backOrderDetailsClassName
//                 backorder
//                 klaviyoTriggerId
//                 preorder
//                 watchlist
//                 watchlistFormTriggerClassName
//                 watchlistDescription:_rawWatchlistDescription
//               }
//               listingImage {
//                 crop: _rawCrop
//                 hotspot: _rawHotspot
//                 asset {
//                   _id
//                 }
//               }
//             }
//             listingImage {
//               crop: _rawCrop
//               hotspot: _rawHotspot
//               asset {
//                 _id
//                 filename
//                 altText
//               }
//             }
//             variantImages {
//               ... on SanityImage {
//                 asset {
//                   _id
//                   filename
//                   altText
//                 }
//               }
//             }
//             hide
//             visibilityRegions
//           }
//         }
//       }
//       upsells: allSanityShopifyProduct {
//         nodes {
//           title
//           shortTitle
//           summary
//           slug {
//             current
//           }
//           _id
//           _type
//           enabled
//           shopifyId
//           yotpoProductId
//           visibilityRegions
//           mainImage {
//             _key
//             _type
//             asset: _rawAsset
//           }
//           productGroups {
//             title
//             _type
//             slug {
//               current
//             }
//           }
//           priceFrom
//           variants {
//             variantTitle
//             listingVariantTitle
//             sku
//             size
//             color
//             flavor
//             configuration
//             shopifyId
//             shopifyIdBase64Encoded
//             variantComponents {
//               shopifyIdBase64Encoded
//               price
//               compareAtPrice
//               sku
//               variantTitle
//               status {
//                 backOrderDetailsClassName
//                 backorder
//                 klaviyoTriggerId
//                 preorder
//                 watchlist
//                 watchlistFormTriggerClassName
//                 watchlistDescription:_rawWatchlistDescription
//               }
//               listingImage {
//                 crop: _rawCrop
//                 hotspot: _rawHotspot
//                 asset {
//                   _id
//                 }
//               }
//             }
//             listingImage {
//               crop: _rawCrop
//               hotspot: _rawHotspot
//               asset {
//                 _id
//                 filename
//                 altText
//               }
//             }
//             hide
//           }
//           youMightAlsoLike {
//             _id
//             _type
//             title
//             shopifyId
//             yotpoProductId
//             visibilityRegions
//             summary
//             slug {
//               current
//             }
//             enabled
//             productGroups {
//               title
//               _type
//               slug {
//                 current
//               }
//             }
//             productOptions: _rawProductOptions
//             variants {
//               variantTitle
//               listingVariantTitle
//               sku
//               size
//               color
//               flavor
//               configuration
//               shopifyId
//               shopifyIdBase64Encoded
//               variantComponents {
//                 shopifyIdBase64Encoded
//                 price
//                 compareAtPrice
//                 sku
//                 variantTitle
//                 status {
//                   backOrderDetailsClassName
//                   backorder
//                   klaviyoTriggerId
//                   preorder
//                   watchlist
//                   watchlistFormTriggerClassName
//                   watchlistDescription:_rawWatchlistDescription
//                 }
//                 listingImage {
//                   crop: _rawCrop
//                   hotspot: _rawHotspot
//                   asset {
//                     _id
//                   }
//                 }
//               }
//               listingImage {
//                 crop: _rawCrop
//                 hotspot: _rawHotspot
//                 asset {
//                   _id
//                   filename
//                   altText
//                 }
//               }
//               variantImages {
//                 ... on SanityImage {
//                   asset {
//                     _id
//                     filename
//                     altText
//                   }
//                 }
//               }
//               hide
//               visibilityRegions
//             }
//           }
//         }
//       }
//       shopifyProducts: allShopifyProduct {
//         nodes {
//           shopifyId
//           title
//           options {
//             shopifyId
//             name
//             values
//           }
//           variants {
//             availableForSale
//             shopifyId
//             price
//             presentmentPrices {
//               compareAtPrice {
//                 amount
//                 currencyCode
//               }
//               price {
//                 amount
//                 currencyCode
//               }
//             }
//             selectedOptions {
//               name
//               value
//             }
//           }
//         }
//       }
//     }
//   `)


//   let products = type === "upsells" ? queryData?.upsells?.nodes || [] : queryData?.under50?.nodes || []
  

//   // Filter by region visiblity
//   products = products.filter((product) => isVisibleIn(product?.visibilityRegions));
//   products.forEach((product) => {
//     if (product?.youMightAlsoLike) {
//       product.youMightAlsoLike = product?.youMightAlsoLike.filter((_product) => isVisibleIn(_product?.visibilityRegions) && _product?.enabled);
//       product.youMightAlsoLike.forEach((upsellProduct) => {
//         upsellProduct.variants = upsellProduct.variants.filter((_variant) => !_variant?.hide && isVisibleIn(_variant?.visibilityRegions))
//       });
//     }
//   });

//   const shopifyProducts = queryData?.shopifyProducts?.nodes || []
//   const productIdsInCart: LineItemMap[] = useMemo(() => {
//     return lineItems
//       .filter((lineItem: any) => Boolean(lineItem?.merchandise))
//       .map((lineItem: any) => {
//         return {
//           productId: lineItem?.merchandise.product.id,
//           variantId: lineItem?.merchandise.id,
//         }
//       })
//   }, [lineItems])

//   products.map((product) => {
//     product?.youMightAlsoLike?.map((upsell) => {filterUpsellsByAvailability(upsell, shopifyProducts)})
//   });

//   const crossProducts = useMemo(() => {
//     return productIdsInCart
//       .map(({ productId, variantId }) => {
//         const matchingProduct = products.find(
//           ({ yotpoProductId }: { yotpoProductId: string }) => variantIdFromShopifyId(productId) === yotpoProductId
//         )

//         if (!matchingProduct) {
//           return null
//         }

//         // Get size of variant
//         const matchingVariant = matchingProduct.variants.find(
//           (variant: any) => variant.shopifyId === variantIdFromShopifyId(variantId)
//         )

//         if (!matchingVariant) {
//           return null
//         }

//         const { size: matchingSize } = matchingVariant

//         const existingProductIds = productIdsInCart.map(
//           ({ productId: exProdId }) => variantIdFromShopifyId(exProdId)
//         )

//         let potentialUpsells = (matchingProduct?.youMightAlsoLike || [])
//           .filter((entry: any) => !existingProductIds.includes(entry.yotpoProductId))


//         // Skip if there is no potential candidates
//         if (potentialUpsells.length === 0) {
//           return null
//         }

//         // Probably should turn this into a size map
//         const sizeUpsells = potentialUpsells
//           .map((sizeProduct: any) => {
//             let sizeVariantMatches = []

//             // Will try to match by size if we have a size.
//             if (matchingSize) {
//               sizeVariantMatches = sizeProduct.variants.filter(
//                 (variant: any) => mapSizeValue(variant.size) === matchingSize
//               )
//             }

//             // For products without it will fall back to the first variant
//             if (sizeVariantMatches.length === 0) {
//               // If there is still variants, include it even if we couldn't match a size
//               const remainingVariants = getValue(sizeProduct, "variants")
//               const sizedVariants = remainingVariants.filter(
//                 (variant: any) => !variant.size || mapSizeValue(variant.size) === "S"
//               )

//               if (sizedVariants) {
//                 sizeProduct.matchingVariants = sizedVariants

//                 return sizeProduct
//               }

//               return null
//             }

//             sizeProduct.matchingVariants = sizeVariantMatches

//             return sizeProduct
//           })
//           .filter((match: any) => match !== null)

//         return potentialUpsells
//       })
//       .filter((match: any) => match !== null)
//   }, [productIdsInCart])

//   let allUpsells = useMemo(() => {
//     let allUpsells: any[] = []
//     crossProducts.forEach((item) => {
//       allUpsells = allUpsells.concat(item);
//     })

//     if (!allUpsells || allUpsells.length === 0) return []

//     const removeDuplicates = (arr, field = "_id") => arr.filter(
//       (a, i) => arr.findIndex((s) => a[field] === s[field]) === i
//     )

//     const removeNoMatchingVariants = (upsells) => upsells.filter(
//       (upsell) => upsell.matchingVariants && upsell.matchingVariants.length !== 0
//     )

//     let uniqueUpsells: any[] = removeNoMatchingVariants(removeDuplicates(allUpsells));

//     uniqueUpsells.forEach(upsell => {
//       const { yotpoProductId, matchingVariants } = upsell
//       const matchingShopifyProduct = shopifyProducts.find((product: any) => variantIdFromShopifyId(product.shopifyId) === yotpoProductId)
//       upsell.klaviyo = queryData?.klaviyo?.nodes[0];

//       if (matchingShopifyProduct) {
//         matchingVariants.forEach((matchingVariant: any) => {
//           const matchingShopifyProductVariant = matchingShopifyProduct.variants.find((shopifyVariant: any) => {
//             return variantIdFromShopifyId(shopifyVariant.shopifyId) === matchingVariant.shopifyId
//           })
//           matchingVariant.price = matchingShopifyProductVariant
//             ? getPriceFromVariantPresentmentPriceNodes(matchingShopifyProductVariant.presentmentPrices)
//             : matchingVariant.price // Get correct price by region

//         })
//       }
//     })

//     return uniqueUpsells
//   }, [crossProducts])

//   if (type === "under50") {
//     // logic for under 50
//     allUpsells = products
//       ?.filter(
//         (product: any) =>
//           Number(product.priceFrom) < 50 &&
//           isVisibleIn(product?.visibilityRegions) &&
//           product?.enabled &&
//           product?.variants?.length > 0
//       )
//       ?.filter(
//         ({ yotpoProductId }: { yotpoProductId: string }) =>
//           !productIdsInCart.find(
//             ({ productId }) =>
//               variantIdFromShopifyId(productId) === yotpoProductId
//           )
//       )
//       ?.map((product: any) => ({
//         ...product,
//         variants: product.variants.filter(
//           (variant: any) =>
//             Number(variant.price) < 50 &&
//             isVisibleIn(variant?.visibilityRegions) &&
//             !variant?.hide
//         ),
//         klaviyo: queryData?.klaviyo?.nodes[0],
//       }))
//       ?.map((product: any) => ({
//         ...filterUpsellsByAvailability(product, shopifyProducts),
//       }))
//   }

  const trackUpsellClick = () => {
    trackEvent("under_50_upsell_click", {})
  }

//   if (!allUpsells || allUpsells.length === 0) return <></>
// >>>>>>> main

  let settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
		nextArrow: <SliderArrowComponent onOtherClick={type === "under50" ? trackUpsellClick : () => {}}/>,
		prevArrow: <SliderArrowComponent rotate={true} onOtherClick={type === "under50" ? trackUpsellClick : () => {}} />,
    className: 'cart-upsell-slider'
  }

  return (
    <div className="relative">
      <Slider {...settings}>
        {data.map((product: any, index: any) => (
          <UpsellItem 
            key={index} 
            product={product} 
            // @ts-ignore
            trackClick={type === "under50" ? trackUpsellClick : () => {}} 
            isUnder50={type === "under50"} />
        ))}
      </Slider>
    </div>
  )
}