import {
  removeAddedCrossSellProducts,
  setAddedCrossSellProducts,
  setCouponDiscountDisplay,
  setCurrentVariant,
  setDiscountDisplay,
  setPrices,
  setQuantity,
} from "@src/actions/product"

const initialState = {
  currentVariant: {},
  price: null,
  priceRaw: null,
  compareAtPrice: null,
  compareAtPriceRaw: null,
  accumulatedPrice: null,
  accumulatedPriceRaw: null,
  discountDisplay: null,
  couponDiscountDisplay: {
    coupon: null,
    amount: null,
  },
  quantity: 1,
  addedCrossSellProducts: [],
}

const productSlice = (set, get) => ({
  ...initialState,

  setCurrentVariant: (variant, product, sanityDiscountCodes) => {
    setCurrentVariant(set, get, variant)
    setPrices(set, get, variant)

    product && setDiscountDisplay(set, variant, product)
    sanityDiscountCodes &&
      setCouponDiscountDisplay(set, get, product._id, sanityDiscountCodes)
  },

  setAddedCrossSellProducts: products =>
    setAddedCrossSellProducts(set, get, products),

  removeAddedCrossSellProducts: products =>
    removeAddedCrossSellProducts(set, get, products),

  setQuantity: quantity => setQuantity(set, get, quantity),

  setCouponDiscountDisplay: (productId, sanityDiscountCodes) => setCouponDiscountDisplay(set, get, productId, sanityDiscountCodes)
})

export default productSlice
