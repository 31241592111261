import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form"
import AlertDialog from '../alert';
import { useSiteSettingsStore } from '@src/hooks/useStore';

const KlaviyoBackInStock = ({ data, variantId }) => {
  const { setAlert, siteSettings } = useSiteSettingsStore(["setAlert" , "siteSettings"]);

  const klaviyoSettings = siteSettings?.klaviyo || {};

  const { unreleasedProducts, backInStockButtonText, unreleasedProductButtonText } = klaviyoSettings;

  const [klaviyoState, setKlaviyoState] = useState({
    email: "",
    klaviyoButtonText: backInStockButtonText,
    hasError: false,
    errorMessage: ""
  });

  const { email, klaviyoButtonText, hasError, errorMessage } = klaviyoState;

  const isUnreleased = unreleasedProducts?.some(obj => obj.shopifyId === variantId);

  useEffect(() => {
    setKlaviyoState({
      ...klaviyoState,
      klaviyoButtonText: isUnreleased ? unreleasedProductButtonText : backInStockButtonText
    });
  }, [variantId, klaviyoSettings]);

  const isEmailValid = (email) => /^[^\s@]+@[^\s@]+.[^\s@]+$/.test(email);

  const { handleSubmit } = useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = async () => {
    if (!isEmailValid(email)) {
      setKlaviyoState({
        ...klaviyoState,
        hasError: true,
        errorMessage: "Please write a valid email!"
      });

      return;
    }

    setIsSubmitting(true);

    try {
      await fetch('/api/back-in-stock', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          variant: variantId,
        })
      })
      .then(() => {
        setAlert(<AlertDialog>Back in Stock form was submitted successfully!</AlertDialog>)
      })

      setIsSubmitted(true);
    } catch (error) {
      console.error(error);
      setAlert(<AlertDialog>There was an error submitting the Back in Stock form. Please try again later.</AlertDialog>)
      setKlaviyoState({
        ...klaviyoState,
        hasError: true,
        errorMessage: "There was an error submitting the form. Please try again later."
      });
    }

    setIsSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-2.5" tabIndex={0} aria-label='Back in Stock form'>
      <div
        className="flex flex-col gap-y-2 border border-gray rounded-xl p-5"
      >
        <label
          htmlFor="emailInput"
          className="text-sm leading-none font-medium font-owners"
        >
          <span className={`text-black ${hasError ? 'text-red-600' : ''}`}>Your email *</span>
        </label>
        <input
          id="emailInput"
          name="emailInput"
          type="email"
          aria-label="Email Input"
          className="appearance-none w-full text-base leading-6 text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out outline-none"
          placeholder="Email address"
          value={email}
          onChange={(e) => setKlaviyoState({
            ...klaviyoState,
            email: e.target.value,
          })}
        />
      </div>
      {hasError &&
        <p className="text-[#a50000] mt-2">{errorMessage}</p>}
      <button
        className="btn btn-secondary hover:btn-primary w-full"
        type="submit"
        disabled={isSubmitting || isSubmitted}
      >
        {isSubmitted ? "Thank You!" : klaviyoButtonText}
      </button>
      {isUnreleased && 
        <div className='block text-left'>This Product is not yet released!</div>}
    </form>
  )
}

export default KlaviyoBackInStock;
