import { useSiteSettingsStore } from "@src/hooks/useStore"
import { useCartStore, useSidebarStore, useSearchStore } from "@src/hooks/useStore" 

function useToggleStates() {
  const { toggleBackdrop } = useSiteSettingsStore(["toggleBackdrop"])
  const { toggleSidebar } = useSidebarStore(["toggleSidebar"]);
  const { toggleSearch } = useSearchStore(["toggleSearch"]);
  const { toggleDrawer } = useCartStore(["toggleDrawer"])

  const types = [
    {
      type: "search",
      toggle: toggleSearch,
    },
    {
      type: "sidebar",
      toggle: toggleSidebar,
    },
    {
      type: "drawer",
      toggle: toggleDrawer,
    },
  ]

  const toggleAllFunction = async (type, override) => {
    const found = types.find(t => t.type === type)
    const otherTypes = types.filter(t => t.type !== type)

    if (type) {
      await Promise.all([found.toggle(override), toggleBackdrop(override)])
    } else {
      await toggleBackdrop(false)
    }

    await Promise.all(otherTypes.map(t => t.toggle(false)))
  }

  return { toggleAllFunction }
}
export default useToggleStates
