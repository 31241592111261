import React from "react"
import { getImageData } from "@utils/image"
import CartToggle from "@components/cart/cart-toggle"
import HamburgerMenu from "@svgs/hamburger-menu"
import Logo from "@components/logo"

const MobileHeader = ({ onHamburgerClick }) => (
  <div className="md:hidden bg-white grid grid-cols-12 py-4 px-5 rounded-xl my-small max-xs:mt-[84px] mt-20 mx-large subquote z-[51]">
    <div className="col-span-3 flex justify-start items-center">
      <HamburgerMenu onClick={onHamburgerClick}/>
    </div>
    <Logo className="flex h-5 col-span-6 justify-center" />
    <div className="col-span-3 flex justify-end items-center">
      <CartToggle />
    </div>
  </div>
)
export default MobileHeader