import React from "react"
import {
  checkOptionChecked,
  checkStatus,
  getSelectedDisplayValue,
} from "@utils/product"
import ScreenReaderText from "@components/screen-reader-text"
import SwatchItem from "../swatch/swatch-item"

const VariantChips = ({
  option,
  variants,
  currentVariant,
  productOptions,
  optionLabels,
  handleOptionChange,
}) => {
  const { name, values, originalIndex } = option

  const isColor = name === "Color" || name === "Flavor"

  return (
    <>
      <p className="text-base font-medium mb-3">Select {name}:</p>
      <div className="variant-chips flex flex-wrap gap-2" key={originalIndex}>
        {values.map((value, index) => {
          const { isDisabled, isUnavailable, isOnSale, isAvailableWithPrime } =
            checkStatus(name, value, variants, currentVariant)
          const isSelected = checkOptionChecked(name, value, currentVariant)
          const selectedDisplayValue = getSelectedDisplayValue(
            name,
            value,
            productOptions
          )
          const formattedDisplayValue = selectedDisplayValue && isSelected && selectedDisplayValue !== value 
            ? selectedDisplayValue
              .replace(/(\d+)"L/g, 'Length: $1 inches')
              .replace(/(\d+)"W/g, 'Width: $1 inches')
              .replace(/(\d+)"H/g, 'Height: $1 inches')
              .replace(/\s*x\s*/g, ', by ')
            : ""

          return (
            <div className="variant-chips flex flex-wrap gap-2" key={"variant-chips-" + index}>
              <ScreenReaderText
                srText={`${isOnSale ? "On Sale: " : ""} ${
                  isUnavailable ? "Out of Stock: " : ""
                }${
                  isAvailableWithPrime && isUnavailable
                    ? "Available with Prime Only"
                    : ""
                } ${name} ${value} ${isSelected ? ", selected" : ""}`}
              />
              <button
                key={originalIndex + value}
                className={`chip py-[5px] px-[13px] rounded-full border flex items-center gap-2 ${
                  isSelected
                    ? "border-soft-black"
                    : "text-inherit fill-soft-black bg-transparent border-1 border-solid border-darker-gray"
                }`}
                onClick={() => {
                  if (!isDisabled) {
                    handleOptionChange(originalIndex, { target: { value } })
                  }
                }}
                aria-label={`
                  ${isOnSale ? "On Sale: " : ""} 
                  ${isUnavailable ? "Out of Stock: " : ""}
                  ${isAvailableWithPrime && isUnavailable
                    ? "Available with Prime Only"
                    : ""
                  }
                  ${name} 
                  ${value} 
                  ${isSelected ? ", selected" : ""} 
                  ${formattedDisplayValue}
                `}
              >
                {isColor && (
                  <SwatchItem
                    key={`swatch-${index}`}
                    name={name}
                    value={value}
                    status={{
                      isDisabled,
                      isUnavailable,
                      isOnSale,
                    }}
                    compactWidth={true}
                    originalIndex={originalIndex}
                    hideLabels={true}
                    insideOfButton={true} //for scr readers to not show value since it reads button contents
                  />
                )}
                {value}
                {isSelected && selectedDisplayValue !== value && (
                  <div
                    className="text-black text-[12px] font-light empty:hidden"
                    aria-hidden={true}
                  >
                    {selectedDisplayValue}
                  </div>
                )}
                {optionLabels(isOnSale, isUnavailable, isAvailableWithPrime)}
              </button>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default VariantChips
