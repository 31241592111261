import React from "react"
import { useUID } from "react-uid"
import { handleFileChange, validateFile } from  './helpers'
import FileCard from './file-card'

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const Field = ({
  id,
  className,
  name,
  type = 'text',
  label,
  value,
  placeholder,
  register,
  required = false,
  errors,
  multiple,
  isGroup,
  selectedFiles,
  setSelectedFiles,
  onChange,
  onFocus,
  onBlur,
  rows,
}) => {
  const uid = useUID()

  if (!placeholder) {
    placeholder = label
  }

  const handleRef = ref => {
    if (register) {

      let params = {
        required
      }

      if (type === "email") {
        params = {
          ...params,
          pattern: {
            value: EMAIL_REGEX,
            message: "Invalid email address",
          },
        }
      }

      if (type === 'file') {
        params = {
          ...params,
          validate: () => validateFile(selectedFiles),
        }
      }

      register(ref, params)
    }
  }

  return (
    <>
    <div
      className={`flex ${isGroup ? 'flex-row ' : 'flex-col gap-y-2.5'} ${className || ''}`}
    >
      {label && <label
        htmlFor={uid}
        className={`${!isGroup ? 'text-sm leading-none font-medium font-owners' : ''}`}
      >
        <span className={`text-black ${errors?.[name] && !isGroup ? 'text-red-600' : ''}`}>{label}{required && !isGroup ? '*' : ''}</span>
      </label>}
      {type !== "textarea" && (
        <input
          id={id || uid}
          name={name}
          aria-label={label}
          type={type}
          className="outline-none w-[inherit]"
          placeholder={placeholder}
          ref={handleRef}
          multiple={type === 'file' && multiple}
          onChange={type === 'file' ? (e) => handleFileChange(e, setSelectedFiles) : onChange}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
      {type === "textarea" && (
        <textarea
          id={id || uid}
          name={name}
          aria-label={label}
          rows={rows}
          placeholder={placeholder}
          ref={handleRef}
        ></textarea>
      )}
      {type === 'file' && (
        <div className="absolute top-[50%] right-2 translate-y-[-50%] select-none">{selectedFiles?.length} / 5</div>
      )}
    </div>
    {selectedFiles?.length > 0 && (
      <div className="flex flex-row gap-3 flex-wrap flex-grow relative mb-8 sm:mb-0 sm:col-span-2">
        {selectedFiles.map((file, index) => (
          <FileCard key={index} index={index} file={file} setSelectedFiles={setSelectedFiles} />
        ))}
      </div>
    )}
    </>
  )
}

export default Field
