import { useRef, useEffect } from "react";
function useFirstRender(callback) {
  const hasRun = useRef(false);
  useEffect(() => {
    if (!hasRun.current) {
      callback();
      hasRun.current = true; // Mark the callback as having been run
    }
  }, [callback]);
}
export default useFirstRender;