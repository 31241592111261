import { setCookie, getCookie } from "@analytics/cookie-utils"

export const checkAndPushToSearch = (array, query) => {
	if (array && query && query !== '') {
		if (array.length > 5) {
			array.shift();
			!array.includes(query?.toLowerCase()) && array.push(query?.toLowerCase());
		} else {
			!array.includes(query?.toLowerCase()) && array.push(query?.toLowerCase())
		}

		return array
	}

	return [];
}

export const getSearchCookie = () => {
	return JSON.parse(getCookie('latest-search') || '[]')
}

export const setSearchCookie = (search) => {
	setCookie('latest-search', JSON.stringify(checkAndPushToSearch(getSearchCookie(), search)), 6 * 30 * 24 * 60 * 60)
}