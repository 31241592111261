import React from 'react'
import { Link } from "gatsby"
import { ReactComponent as Instagram } from "@svgs/instagram.svg";
import { ReactComponent as Facebook } from "@svgs/facebook.svg";
import { ReactComponent as Youtube } from "@svgs/youtube.svg";
import { ReactComponent as TikTok } from "@svgs/tiktok.svg";
import { ReactComponent as Pinterest } from "@svgs/pinterest.svg";
import { ReactComponent as Twitter } from "@svgs/twitter.svg";

const Icon = {
	facebook: <Facebook />,
	instagram: <Instagram />,
	youtube: <Youtube />,
	twitter: <Twitter />,
	pinterest: <Pinterest />,
	tiktok: <TikTok />,
};

const SocialMedia = ({ footerData }) => {
	const { socials } = footerData;
	
	return (
		<div className='hidden md:flex px-10 py-5 justify-between items-center border-t-1 border-solid border-gray'>
			{socials &&
				socials.map((item) => (
					<Link key={React.useId()} to={item.href} target='_blank' aria-label={item.title + ", opens in a new tab"}>
						{Icon[item.title.toLowerCase()]}
					</Link>
				))}
		</div>
	);
};

export default SocialMedia
