import React from 'react';

const Container = ({ isFluid, isSlider, innerContainerClassName, children }) => {
  const containerClassName = isFluid ? 'container-fluid' : 'container';
  const innerClassName = `w-full gap-small md:gap-5 flex ${innerContainerClassName || ''}`;

  return (
    <div className={`${containerClassName} ${isSlider ? 'overflow-hidden' : ''}`}>
      <div className={innerClassName}>{children}</div>
    </div>
  );
};

export default Container;
