import React, { useState } from "react"
import CartUpsell from "../upsell"
import { trackEvent } from "@src/services/analytics"

export default function ShowUpsell() {
  const [type, setType] = useState("upsells")

  const trackRecommendedClick = () => {
    trackEvent("upsell_recommended_tab_click", {})
  }

  const trackUnder50Click = () => {
    trackEvent("upsell_under_50_tab_click", {})
  }

  return (
    <>
      <div className="gap-x-4 upsell-buttons hidden">
        <button
          onClick={() => {setType("upsells"); trackRecommendedClick()}}
          className={`underline text-darker-gray hover:text-soft-black cursor-pointer ${
            type === "upsells" ? "text-soft-black font-medium" : ""
          }`}
        >
          Recommended
        </button>

        <button
          onClick={() => {setType("under50"); trackUnder50Click()}}
          className={`underline text-darker-gray hover:text-soft-black cursor-pointer ${
            type === "under50" ? "text-soft-black font-medium" : ""
          }`}
        >
          Under $50 Picks
        </button>
      </div>

      <CartUpsell type={type} />
    </>
  )
}
