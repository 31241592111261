import React from "react";
import { useSidebarStore } from "@src/hooks/useStore";

const HamburgerMenu = ({ onClick }) => {
	const { sidebarOpened } = useSidebarStore(["sidebarOpened"]);

	return (
		<button onClick={onClick} aria-label="Main Menu" aria-expanded={sidebarOpened ? "true" : "false"} tabIndex={0}>
			<svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
				<path d="M0 2V0H18V2H0Z" fill="#191818" />
				<path d="M0 8V6H18V8H0Z" fill="#191818" />
				<path d="M0 14V12H18V14H0Z" fill="#191818" />
			</svg>
		</button>
	);
}

export default HamburgerMenu;
