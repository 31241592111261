import React from "react"
import { toggleSidebar, setContent, setSettings } from "@src/actions/sidebar";

const initialState = {
    content: <></>,
    settings: {
      showSearchbar: true,
      showCloseButton: true,
      className: "",
    },
    sidebarOpened: false,
}

const sidebarSlice = (set) => ({
    ...initialState,

    toggleSidebar: (override) => toggleSidebar(set, override),

    setContent: (content) => setContent(set, content),

    setSettings: (settings) => setSettings(set, settings),
});

export default sidebarSlice;