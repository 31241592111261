import React from "react"

import {
  addLineItemsToCart,
  fetchCart,
  fetchOrCreateCart,
  processLineItems,
  removeLineItemsFromCart,
  setUpsells,
  updateDiscount,
  updateLineItemsInCart,
} from "@src/utils/cart";
import AlertDialog from "@src/components/alert";
import { set } from "dot-prop";

export const initializeCart = async (set, get) => {
  await fetchOrCreateCart(set, get);
}

export const addLineItems = async (set, get, lines) => {
  set((state) => ({
    cartStore: {
      ...state.cartStore,
      loading: true,
    }
  }));

  const { siteSettingsStore: { setAlert }, cartStore: { cart } } = get();

  setAlert(<AlertDialog>Product was added to cart</AlertDialog>);

  const cartId = cart?.id;

  const processedLines = processLineItems(lines);

  try {
    const updatedCart = await addLineItemsToCart(cartId, processedLines);

    set((state) => ({
      cartStore: {
        ...state.cartStore,
        cart: {
          ...state.cartStore.cart,
          ...updatedCart,
        },
        loading: false,
      }
    }));

    console.log("Added line items:", updatedCart);
    await setUpsells(set, get)
    await checkAndReinitializeIfBundleInCart(set, get);

    return updatedCart;
  } catch (error) {
    console.log("Error adding line items:", error);
    return null;
  }
};

export const removeLineItems = async (set, get, cartId, lineIds) => {
  set((state) => ({
    cartStore: {
      ...state.cartStore,
      loading: true,
    }
  }));

  try {
    const updatedCart = await removeLineItemsFromCart(cartId, lineIds);

    set((state) => ({
      cartStore: {
        ...state.cartStore,
        cart: {
          ...state.cartStore.cart,
          ...updatedCart,
        },
        loading: false,
      }
    }));

    console.log("removed line items:", updatedCart);
    await setUpsells(set, get)

    return updatedCart;
  } catch (error) {
    console.log("Error removing line items:", error);

    return null;
  }
};

export const updateLineItems = async (set, cartId, lines) => {
  try {
    const updatedCart = await updateLineItemsInCart(cartId, lines);

    set((state) => ({
      cartStore: {
        ...state.cartStore,
        cart: {
          ...state.cartStore.cart,
          ...updatedCart,
        },
      }
    }));

    return updatedCart;
  } catch (error) {
    console.log("Error updating line items:", error);

    return null;
  }
};

export const addDiscount = async (set, get, discountCodes) => {
  const { cart } = get().cartStore;
  const cartId = cart?.id;

  try {
    const { updatedCart, userErrors } = await updateDiscount(cartId, discountCodes);

    if (userErrors) {
      console.log("Error adding discount code:", userErrors);
      set((state) => ({
        cartStore: {
          ...state.cartStore,
          cart: {
            ...state.cartStore.cart,
            ...userErrors,
          },
        }
      }));
    } else {
      set((state) => ({
        cartStore: {
          ...state.cartStore,
          cart: {
            ...state.cartStore.cart,
            ...updatedCart,
          },
        }
      }));

      console.log("Added discount code:", updatedCart);
    }

    return { cart: updatedCart, userErrors };
  } catch (error) {
    console.log("Error adding discount code:", error);

    return null;
  }
};

export const removeDiscount = async (set, get) => {
  const { cart } = get().cartStore;
  const cartId = cart.id;

  try {
    const updatedCart = await updateDiscount(cartId);

    set((state) => ({
      cartStore: {
        ...state.cartStore,
        cart: {
          ...state.cartStore.cart,
          ...updatedCart,
        },
      }
    }));

    return updatedCart;
  } catch (error) {
    console.log("Error removing discount code:", error);

    return null;
  }
};

export const toggleDrawer = (set, override) => {
  set((state) => ({
    cartStore: {
      ...state.cartStore,
      isDrawerOpen: override !== null ? override : state.cartStore.isDrawerOpen,
    }
  }));
};

const checkAndReinitializeIfBundleInCart = async (set, get) => {
  const { lines, id } = get().cartStore.cart;
  const hasBundleItems = lines?.find(line => line.attributes?.find(attribute => attribute.key === "_bundle"));

  if (lines?.length && hasBundleItems) {
    await fetchCart(set, get, id).then(res => {
      if (res) {
        set((state) => ({
          cartStore: {
            ...state.cartStore,
            cart: res,
          }
        }));
      }
    })
  }
}
