import React, { useState, useEffect } from "react"
import usFlag from "@images/flag-usd.jpeg"
import caFlag from "@images/flag-cad.jpeg"
import { getCurrentRegion } from "@utils/region"
import { onEnterKeyPress } from "@src/utils/general"
import AlertDialog from "../alert"
import { useSiteSettingsStore } from "@src/hooks/useStore"

const flags = {
  US: usFlag,
  CA: caFlag,
}

const redirects = (pathname = "", params = "") => ({
  US: `https://diggs.pet${pathname}?_country=US${params && "&"}${params}`,
  CA: `https://diggs.ca${pathname}?_country=CA${params && "&"}${params}`,
})

const labels = {
  US: "USD",
  CA: "CAD",
}

const CountryPicker = ({ items, className }) => {
  const { setAlert } = useSiteSettingsStore(["setAlert"])

  const [showDropdown, setShowDropdown] = useState(false)
  const region = getCurrentRegion().isoCode
  const toggleDropdown = () => setShowDropdown(!showDropdown)
  const redirection = region => {
    const pathname = window.location.pathname
    const params = new URLSearchParams(window.location.search)
    params.delete("_country")
    window.location.href = redirects(pathname, params.toString())[region]
  }

  const classList = `${
    className || ""
  } relative w-20 h-full max-md:mx-auto md:min-h-[50px] xl:min-h-[20px] md:py-2.5 md:p-0`
  const innerClassList = `relative md:absolute top-0 right-0 w-full border-gray border rounded-lg p-2.5 xl:p-2 xl:py-0.5 max-w-fit bg-white`

  useEffect(() => {
    const onScroll = () => setShowDropdown(false)
    document.addEventListener("scroll", onScroll)

    return () => document.removeEventListener("scroll", onScroll)
  }, [])

  useEffect(() => {
    setAlert(
      <AlertDialog>
        {showDropdown ? "Dropdown expanded" : "Dropdown collapsed"}
      </AlertDialog>
    )
  }, [showDropdown])

  return (
    <div className={classList}>
      <div className={innerClassList}>
        <div
          onClick={toggleDropdown}
          tabIndex={0}
          aria-label={`Select Region button. Selected region: ${region}, dropdown ${
            showDropdown ? "expanded" : "collapsed"
          }`}
          onKeyDown={e => onEnterKeyPress(e, toggleDropdown)}
          className={`relative flex xl:justify-center items-center cursor-pointer`}
        >
          <img
            className="w-9 rounded-sm"
            src={flags[region]}
            alt={`${region} flag`}
          />
          <span className="flex items-center ml-2 h-7">
            <svg
              className={`${
                showDropdown ? `-rotate-0 transform` : `-rotate-180 transform`
              }`}
              width="10"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M.5 7.996l6.435-7 6.565 7"
                stroke="#191818"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
        <ul
          className={`relative bg-white md:w-full md:left-0 h-auto md:rounded-xl transition-all duration-300 overflow-hidden ${
            showDropdown ? "max-h-64 visible" : "max-h-0 invisible"
          }`}
        >
          {Object.keys(flags)
            .filter(key => key !== region)
            .map(key => (
              <li
                className="pt-2.5 pb-1.5 cursor-pointer flex items-center text-center"
                onClick={() => redirection(key)}
                key={key}
                onKeyDown={e => onEnterKeyPress(e, () => redirection(key))}
                aria-label={`Select region: ${key}`}
                tabIndex={0}
              >
                <img className="w-10 rounded-sm" src={flags[key]} alt={key} />
              </li>
            ))}
        </ul>
      </div>
    </div>
  )
}

export default CountryPicker
