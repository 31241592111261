import React from "react"

const CustomList = ({ 
  children, 
  style = "default", 
  tag = 'ul', 
  className, 
  blockKey 
}) => {
  const copyClasses = new Map([
    [
      'default',
      'text-md',
    ],
  ])

  const classes = copyClasses.get(style)
  let cx = `${classes} ${className || ""}`
  const Wrapper = tag || `ul`

  if (tag === 'ol') {
    cx = `${cx} list-outside ml-4 list-decimal space-y-2`
  }

  if (tag === 'ul') {
    cx = `${cx} list-outside ml-4 list-disc space-y-2 mb-2.5`
  }

  return (
    <Wrapper className={cx} tabIndex={0} key={blockKey}>
      {children}
    </Wrapper>
  )
}

export default CustomList
