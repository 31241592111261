import React, { useEffect, useState } from "react"
import classnames from "classnames"
import getValue from "get-value"
import * as style from "./affirm-promotion.module.css"

const AffirmPromotion = ({ 
  total,
  type = "product",
  center = false, 
  sku 
}) => {
  const [className, setClassName] = useState("flex")
  let formatted = parseFloat(total) * 100
  
  if (isNaN(formatted)) {
    formatted = 0
  }

  useEffect(() => {
    if (typeof affirm !== "undefined" && affirm) {
      const refresh = getValue(affirm, 'ui.refresh')

      if (typeof refresh === 'function') {
        setClassName("flex");
        affirm.ui.refresh()
      }
    } else {
      setClassName("hidden");
    }
  }, [total])

  return (
    <p
      className={classnames([
        `text-xs text-dark-navy affirm-as-low-as is-sticky-hidden ${style.AffirmMessage} ${className || ""}`,
        { "text-center": center },
      ])}
      data-page-type={type}
      data-amount={formatted}
      data-sku={sku}
    ></p>
  )
}

export default AffirmPromotion
