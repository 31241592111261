import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import cartSlice from "@src/slices/cart";
import siteSettingsSlice from "@src/slices/siteSettings";
import sidebarSlice from "@src/slices/sidebar";
import searchSlice from "@src/slices/search";
import quickViewSlice from "@src/slices/quickView";
import productSlice from "@src/slices/product";

// instead of true: !process.env.NODE_ENV === "production"

const useGlobalStore = create(
	immer(
		devtools((...a) => ({
      		cartStore: cartSlice(...a),
			siteSettingsStore: siteSettingsSlice(...a),
			sidebarStore: sidebarSlice(...a),
			searchStore: searchSlice(...a),
			quickViewStore: quickViewSlice(...a),
			productStore: productSlice(...a),
		}), { enabled: true })
	)
);

export default useGlobalStore;