import React from "react"

const Heading = ({ 
  children, 
  priority = 2, 
  level = 2, 
  tag, 
  className
}) => {
  const headingClassMap = new Map([
    [1,""],
    [2,""],
    [3,""]
  ])

  const classes = headingClassMap.get(parseInt(level))
  const cx = `${classes} ${className || ""}`

  if (!priority) {
    priority = level
  }

  const H = `h` + priority

  if (tag) {
    const Custom = tag
    return <Custom className={cx}>{children}</Custom>
  }

  return (
    <H className={cx} tabIndex={0}>
      {children}
    </H>
  )
}

export default Heading
