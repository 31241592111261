import { useSiteSettingsStore } from "@src/hooks/useStore";
import React from 'react'

const DisplayedAlert = () => {
  const { alert } = useSiteSettingsStore(["alert"]);
  
  return (
    <div aria-live="polite">
      {alert || <></>}
    </div>
  )
}

export default DisplayedAlert