export const toggleSidebar = (set, override = null) => {
  set(state => ({
    sidebarStore: {
      ...state.sidebarStore,
      sidebarOpened: override !== null ? override : !state.sidebarStore.sidebarOpened,
    }
  }))
}

export const setContent = (set, content) => {
    set(state => ({
        sidebarStore: {
            ...state.sidebarStore,
            content
        }
    }))
}

export const setSettings = (set, settings) => {
  set(state => ({
    sidebarStore: {
      ...state.sidebarStore,
      settings: {
        ...state.sidebarStore.settings,
        ...settings,
      }
    }
  }))
}
